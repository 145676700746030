import {
  GET_STANDING_INSTRUCTIONS,
  CANCEL_STANDING_INSTRUCTIONS,
  INITIATE_STANDING_INSTRUCTIONS,
  GET_STANDING_INSTRUCTIONS_STATUS,
  VIEW_STANDING_INSTRUCTIONS,
} from "../constants";

import { axiosPrivate } from "@metlife-one-opps/utils";

export const getStandingInstructions = async (policyNumber: string) => {
  const response = await axiosPrivate.get(
    `${GET_STANDING_INSTRUCTIONS}?policyNumber=${policyNumber}`
  );
  return response;
};

export const cancelStandingInstructions = async (policyNumber: string) => {
  const response = await axiosPrivate.post(
    CANCEL_STANDING_INSTRUCTIONS.replace(":policy_number", policyNumber)
  );
  return response;
};

export const initiateStandingInstructions = async (payload: any) => {
  const response = await axiosPrivate.post(INITIATE_STANDING_INSTRUCTIONS, payload);
  return response;
};

export const getStandingInstructionsStatus = async (policyNumber: string) => {
  const response = await axiosPrivate.get(
    `${GET_STANDING_INSTRUCTIONS_STATUS}?policyNumber=${policyNumber}`
  );
  return response;
};

export const viewStandingInstructionsStatus = async (policyNumber: string) => {
  const response = await axiosPrivate.get(
    `${VIEW_STANDING_INSTRUCTIONS}?policyNumber=${policyNumber}`
  );
  return response;
};
