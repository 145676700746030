import React from "react";
import classes from "./MTDesktopMenu.module.css";
import megaMenuLinks from "../../data/mega-menu.json";

type MTDesktopMenuProps = {
  children?: React.ReactElement;
};

const MegaMenuLink = ({ megamenu }: any) => {
  return (
    <div className={classes["mega-menu"]}>
      <div className={classes["mega-menu-heading"]}>
        <h3>
          <a href={megamenu?.link ?? "#"} target="_blank">{megamenu?.title}</a>
        </h3>
      </div>
      <div className={classes["mega-menu-submenu"]}>
        <ul>
          {megamenu?.subMenu.map((submenu: any) => (
            <li key={`submenu1-${submenu?.id}`}>
              <a href={submenu?.link ?? "#"} target="_blank">{submenu.title}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const MTDesktopMenu: React.FC<MTDesktopMenuProps> = () => {
  return (
    <div className={classes.container}>
      <div className="App__fluid-container">
        <div className={classes.innerContainer}>
          <section className={classes.row1}>
            {megaMenuLinks.slice(0, 3).map((mm: any) => (
              <MegaMenuLink megamenu={mm} key={`megamenu-${mm?.id}`} />
            ))}
          </section>
          <div className={classes.divider} />
          <section className={classes.row2}>
            {megaMenuLinks.slice(3).map((mm: any) => (
              <MegaMenuLink megamenu={mm} key={`megamenu2-${mm?.id}`} />
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};
