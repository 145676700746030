import React from "react";
import classes from "./MetLifeDropDown.module.css";
import { ChevronRight } from "@metlife-one-opps/page-container";

type MetLifeDropDownProps = {
  label?: string;
  value?: string;
  onSelect?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  required?: boolean | false;
  placeholder?: string;
  isError?: boolean;
  errMessage?: string;
  optionsList?: Array<any>;
  identifier?: string;
  containerCustomClass?: string;
  wrapperCustomClass?: string;
  style?: React.CSSProperties;
  renderSelectedComponent?: () => React.ReactNode | undefined;
  renderItemComponent?: (item: any) => React.ReactNode | undefined;
};

export const MetLifeDropDown: React.FC<MetLifeDropDownProps> = ({
  label,
  value = "",
  onSelect = () => {},
  optionsList = [],
  identifier = "value",
  type = "text",
  placeholder = "",
  required,
  isError = false,
  errMessage = "This field is invalid.",
  style,
  renderSelectedComponent,
  renderItemComponent,
  containerCustomClass,
  wrapperCustomClass,
  ...props
}) => {
  return (
    <div
      className={`${classes.mainContainer} ${containerCustomClass}`}
      style={style}
    >
      <div
        className={`
            ${classes.inputContainer} 
            ${classes.inputDropDownContainer} 
            ${classes.inputSelectContainer}
            ${isError ? classes["is-invalid"] : ""} 
            ${value ? classes.inputHasValue : ""}
            ${wrapperCustomClass}
          `}
      >
        <div className={classes.selectWrapper}>
          <button className={classes.selected} type="button">
            {renderSelectedComponent ? (
              renderSelectedComponent()
            ) : (
              <div className={classes.value}>{value}</div>
            )}

            <div className={classes.arrow} data-style-target="icon-svg">
              <ChevronRight direction={"up"} height={14} width={14} />
            </div>
          </button>
          <div className={classes.optionList}>
            {optionsList.map((ol: any) =>
              renderItemComponent ? (
                renderItemComponent(ol)
              ) : (
                <div
                  key={ol?.id}
                  className={`${classes.option} ${
                    value === ol[identifier] ? classes.active : ""
                  }`}
                  role="button"
                  onClick={() => onSelect(ol)}
                >
                  <div>{ol[identifier]}</div>
                </div>
              )
            )}
          </div>
        </div>
        <label>{placeholder}</label>
      </div>

      {errMessage && isError && (
        <div className={classes["is-error"]}>{errMessage}</div>
      )}
    </div>
  );
};
