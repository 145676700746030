import {
  POST_INITIATE_PAYMENT_API,
  PUT_START_PAYMENT_API,
  PUT_TERMS_CONDITIONS_API,
  PUT_CONFIRM_PAYMENT_API,
  POST_CREATE_PAYMENT_API,
  POST_FINISH_API,
  GET_PAYMENT_OPTIONS,
} from "../constants";
import {
  ConfirmPaymentTypes,
  InitatePaymentTypes,
  StartPaymentTypes,
  FinishPaymentTypes,
  TermsConditionsTypes,
  CreatePaymentTypes,
} from "./payment-types";
import { axiosPrivate } from "@metlife-one-opps/utils";

/**
 * initate payment
 * @param payload
 * @returns
 */
export const initatePayment = async (payload: InitatePaymentTypes) => {
  const response = await axiosPrivate.post(POST_INITIATE_PAYMENT_API, payload);
  return response;
};

/**
 * start payment
 * @param payload
 * @returns response
 */
export const startPayment = async (payload: StartPaymentTypes) => {
  const response = await axiosPrivate.put(PUT_START_PAYMENT_API, payload);
  return response;
};

/**
 * Sign terms and condtions
 * @param payload
 * @returns response
 */
export const signTermsAndCondtions = async (payload: TermsConditionsTypes) => {
  const response = await axiosPrivate.put(PUT_TERMS_CONDITIONS_API, payload);
  return response;
};

/**
 * confirm payment
 * @param payload
 * @returns response
 */
export const confirmPayment = async (payload: ConfirmPaymentTypes) => {
  const response = await axiosPrivate.put(PUT_CONFIRM_PAYMENT_API, payload);
  return response;
};

/**
 * create payment
 * @param payload
 * @returns response
 */
export const createPaymentService = async (payload: CreatePaymentTypes) => {
  const res = await axiosPrivate.post(POST_CREATE_PAYMENT_API, payload);
  return res;
};

/**
 * finish payment
 * @param payload
 * @returns response
 */
export const finishPayment = async (payload: FinishPaymentTypes) => {
  const res = await axiosPrivate.post(POST_FINISH_API, payload);
  return res;
};

export const getPaymentOptions = async (transactionId: string) => {
  const response = await axiosPrivate.get(
    `${GET_PAYMENT_OPTIONS}?transactionId=${transactionId}`
  );
  return response;
};

export * from "./update-mobileNumber";
export * from "./contact-us";
