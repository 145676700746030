import { useLoader } from "@metlife-one-opps/hooks";
import React, { useEffect, useRef, useState } from "react";
import { CloseIcon } from "../../assets";
import classes from "./ContactUsSlider.module.css";

import { yupResolver } from "@hookform/resolvers/yup";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import {
  MetLifeInput,
  MetLifeSelect,
  MetLifeTextArea,
} from "@metlife-one-opps/metlife-input";
import { contactUsService } from "@metlife-one-opps/services";
import { Controller, useForm } from "react-hook-form";
import { contactUsSchema } from "./contact-us-schema";
import ReCAPTCHA from "react-google-recaptcha";

interface ContactUsSliderProps {
  onClose: () => void;
  isShown: boolean | any;
}
export const ContactUsSlider = ({ onClose, isShown }: ContactUsSliderProps) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactUsSchema),
    mode: "all",
  });
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const { setLoading } = useLoader();
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const contactUsRef = useRef<HTMLDivElement | null>(null);

  const handleEmailFocus = (event: any) => {
    const emailValue = event.target.value;
    const newEmail = emailValue.replace("@example.com", "");
    setValue("email", newEmail);
  };

  const isValidEmailFormat = (email: any) => {
    if (typeof email !== "string") return false;
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e: any) => {
    if (!e.target.value) return;
    let value = e.target.value.trim();
    if (!value.includes("@") && !isValidEmailFormat(value)) {
      value += "@example.com";
    }
    setValue("email", value);
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const token = await recaptchaRef.current?.executeAsync();
      if (!token) {
        setLoading(false);
        return;
      }

      const payload = {
        email: data.email || null,
        address: data.address,
        firstName: data.firstName,
        lastName: data.lastName,
        productIntrestedIn: data.products,
        mobileNumber: data.telephoneNumber,
        message: data.textMessage,
        RecaptchaToken: token,
      };

      const response = await contactUsService(payload);

      if (response?.status === 200) {
        setLoading(false);
        setSubmissionSuccess(true);
      }
    } catch (error) {
      recaptchaRef.current?.reset();
      setLoading(false);
      console.log("error", error);
    }
  };
  useEffect(() => {
    const resetAfterStateChanged = setTimeout(() => {
      reset();
      setSubmissionSuccess(false);
    }, 100);

    return () => clearTimeout(resetAfterStateChanged);
  }, [isShown]);
  const hiddenButtonClick = async () => {
    await recaptchaRef.current?.executeAsync();
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isOutside =
        contactUsRef.current && !contactUsRef.current.contains(target);
      const isIgnoredElement = ["INPUT", "BUTTON", "SVG"].includes(target.nodeName);
      if (isOutside && !isIgnoredElement) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <div
      data-testid="contact-us-container-div"
      className={`${classes.contactUsSlider__outerContainer} ${
        isShown ? classes.show : classes.hide
      }`}
      ref={contactUsRef}
    >
      <div className={classes.contactUsSlider__innerContainer}>
        <a
          role="link"
          aria-label="close"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          <div className={classes.contactClose}>
            <CloseIcon />
          </div>
        </a>
        {submissionSuccess ? (
          <div className={classes.Outer_thank_you}>
            <div className={classes.Inner_thank_you}>
              <h2>Thank you for contacting us.</h2>{" "}
            </div>
            <div className={classes.thanksSubText}>
              <p>We will get back to you within 3-5 business days.</p>
            </div>
          </div>
        ) : (
          <div>
            <div className={classes.heading}>
              <h3>Contact MetLife:</h3>
            </div>
            <div className={classes.subHeading}>
              <p>Fields marked with * are required.</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} data-testid="ContactUs__form">
              <div className={classes.contactUsSlider_form}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <MetLifeInput
                      isError={!!errors?.firstName?.message}
                      errMessage={errors?.firstName?.message}
                      containerCustomClass={classes.outlinedBorder}
                      control={control}
                      inputHasValue={!!field.value}
                      name={"firstName"}
                      placeholder="*First Name"
                      type="text"
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <MetLifeInput
                      isError={!!errors?.lastName?.message}
                      errMessage={errors?.lastName?.message}
                      control={control}
                      containerCustomClass={classes.outlinedBorder}
                      inputHasValue={!!field.value}
                      name={"lastName"}
                      placeholder="*Last Name"
                      type="text"
                    />
                  )}
                />
                <Controller
                  name="telephoneNumber"
                  control={control}
                  render={({ field }) => (
                    <MetLifeInput
                      data-testid="MetLifeInput__text__telephoneNumber"
                      isError={!!errors?.telephoneNumber?.message}
                      errMessage={errors?.telephoneNumber?.message}
                      containerCustomClass={classes.outlinedBorder}
                      control={control}
                      inputHasValue={!!field.value}
                      name={"telephoneNumber"}
                      placeholder="*Telephone number"
                      type={"phone"}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <MetLifeInput
                      data-testid="MetLifeInput__text__email"
                      isError={!!errors?.email?.message}
                      errMessage={errors?.email?.message}
                      containerCustomClass={classes.outlinedBorder}
                      control={control}
                      inputHasValue={!!field.value}
                      name={"email"}
                      onBlur={(e) => handleEmailChange(e)} // Use the custom change handler
                      onFocus={handleEmailFocus}
                      placeholder="Email"
                      type="text"
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <MetLifeTextArea
                      isError={!!errors?.address?.message}
                      errMessage={errors?.address?.message}
                      containerCustomClass={classes.outlinedBorder}
                      control={control}
                      inputHasValue={!!field.value}
                      name={"address"}
                      placeholder="*Address"
                    />
                  )}
                />
                <Controller
                  name="products"
                  control={control}
                  render={({ field }) => (
                    <MetLifeSelect
                      name="products"
                      containerCustomClass={classes.outlinedBorder}
                      control={control}
                      inputHasValue={!!field.value}
                      identifier="value"
                      label="Dropdown"
                      placeholder="Products Intereseted in"
                      optionsList={[
                        { id: 1, label: "Family", value: "Family" },
                        { id: 2, label: "Health", value: "Health" },
                        { id: 3, label: "Future", value: "Future" },
                        {
                          id: 4,
                          label: "Group Life Insuarance",
                          value: "Group Life Insuarance",
                        },
                        { id: 5, label: "Education", value: "Education" },
                      ]}
                      isError={!!errors?.products?.message}
                      errMessage={errors?.products?.message}
                    />
                  )}
                />
                <Controller
                  name="textMessage"
                  control={control}
                  render={({ field }) => (
                    <MetLifeTextArea
                      containerCustomClass={classes.outlinedBorder}
                      control={control}
                      inputHasValue={!!field.value}
                      name={"textMessage"}
                      placeholder="Insert Your Message"
                      isError={!!errors?.textMessage?.message}
                      errMessage={errors?.textMessage?.message}
                    />
                  )}
                />
                <button
                  data-testid="pd-hidden-button"
                  id="hiddenButton"
                  style={{ display: "none" }}
                  onClick={hiddenButtonClick}
                >
                  Hidden Button
                </button>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={
                    window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_RECAPTCHA_KEY || ""
                  }
                />
                <div className={classes.PaymentOverview__agreement}>
                  By clicking submit, you are agreeing to the&nbsp;
                  <button type="button">
                    <a
                      data-testid="privacy-policy-link"
                      href="https://www.metlife.com.np/en/privacy-policy/"
                      target="blank"
                    >
                      Privacy Statement&nbsp;
                    </a>
                  </button>
                  and
                  <button type="button">
                    <a
                      data-testid="terms-conditions-link"
                      href="https://www.metlife.com.np/en/terms-and-conditions/"
                      target="blank"
                    >
                      &nbsp;Terms and Conditions&nbsp;
                    </a>
                  </button>
                  of Metlife Nepal.
                </div>
                <div className={classes.contactUsSlider_button}>
                  <MetLifeButton type="submit">Submit</MetLifeButton>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
