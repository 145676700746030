import {
  DECIMAL_PLACES,
  NUMBERS_ONLY,
  VALUES_BETWEEN_ERROR,
} from "../../../constants";

export const paymentGatewayRule = (premiumAmt: any) => {
  return {
    required: {
      value: true,
      message: "This field is mandatory ",
    },
    validate: {
      isNumber: (value: string) =>
        !/^\d+(\.\d+)?$/.test(value) ? NUMBERS_ONLY : undefined,
      hasTwoDecimalPlaces: (value: string) =>
        !/^\d+\.\d{2}$/.test(value) ? DECIMAL_PLACES : undefined,
    },
    min: {
      value: 1,
      message: VALUES_BETWEEN_ERROR("1.00", premiumAmt),
    },
    max: {
      value: premiumAmt,
      message: VALUES_BETWEEN_ERROR("1.00", premiumAmt),
    },
  };
};
