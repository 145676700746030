import React from "react";
import classes from "./Loader.module.css";

export const Loader = () => {
  return (
    <>
      <div className={classes["MetLife-Loader"]}>
        <div className={classes["loader-wrapper"]}>
          <div data-title="dot-typing">
            <div className={classes["stage"]}>
              <div className={classes["dot-typing"]}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
