// React + TS component for a input field with material ui like styling

import { CalenderIcon, CheckboxIcon } from "@metlife-one-opps/page-container";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { INPUT_DATE_PICKER_FORMAT, INPUT_TYPE_PHONE } from "../constants";
import classes from "./MetLifeInput.module.css";
import "./react-datepicker-custom.css";

type MetLifeInputProps = {
  label?: string;
  value?: any;
  onChange?: (val: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void; // Add this line
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void; // Add this line
  defaultValue?: string;
  type?: string;
  required?: boolean | false;
  placeholder?: string;
  isError?: boolean;
  errMessage?: any;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  style?: React.CSSProperties;
  containerCustomClass?: string;
  register?: any;
  inputHasValue?: any;
  control?: any;
  name?: any;
  prePopulate?: any;
  prePopulateItemClass?: string;
  onInput?: (e: any) => void;
  onPaste?: (e: any) => void;
};

export const MetLifeInput: React.FC<MetLifeInputProps> = forwardRef(
  (
    {
      label,
      name = "",
      min = 0,
      max,
      minLength,
      prePopulate,
      prePopulateItemClass = "",
      maxLength,
      placeholder = "",
      required,
      type = "text",
      isError = false,
      errMessage = "This field is invalid.",
      style,
      containerCustomClass,
      control,
      defaultValue,
      inputHasValue,
      onInput,
      onChange,
      onBlur,
      onFocus,
      onPaste,
      ...props
    },
    ref: any
  ) => {
    const handleKeyDown = (event: React.KeyboardEvent) => {
      const isNumber = /^\d$/.test(event.key);
      const isFunctionalShortcut =
        (event.ctrlKey || event.metaKey) &&
        ["c", "v", "a", "x"].includes(event.key.toLowerCase());
      const isBackspace = event.key === "Backspace";

      if (
        type === INPUT_TYPE_PHONE &&
        !isNumber &&
        !isFunctionalShortcut &&
        !isBackspace
      ) {
        event.preventDefault();
      }
    };
    return (
      <div
        className={`${classes.mainContainer} ${containerCustomClass}`}
        style={style}
      >
        <div
          className={`
                  ${classes.inputContainer} 
                  ${!!prePopulate && classes.hasPrepopulateItem}
                  ${isError ? classes["is-invalid"] : ""} 
                  ${!!inputHasValue ? classes.inputHasValue : ""}
                `}
        >
          <input
            {...control.register(name)}
            data-testid={`MetLifeInput__text__${name}`}
            maxLength={maxLength}
            minLength={minLength}
            defaultValue={defaultValue}
            min={min}
            max={max}
            type={type}
            onInput={onInput}
            onBlur={onBlur}
            onPaste={onPaste}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
            className={`${!!inputHasValue ? classes["has-value"] : ""}`}
          />
          {prePopulate && (
            <div
              className={`${classes["pre-populate-item"]} ${prePopulateItemClass}`}
            >
              {prePopulate}
            </div>
          )}
          <label>{placeholder}</label>
        </div>
        {errMessage && isError && (
          <div className={classes["is-error"]}>{errMessage}</div>
        )}
      </div>
    );
  }
);

type InputDatePickerProps = {
  showYearPicker?: boolean;
  selected: Date | null;
  maxDate: Date | null;
  minDate: Date | null;
  onChange?: (date: Date) => void;
  onChangeRaw?: (e: any) => void;
  placeholderText?: string;
  dateFormat?: string;
  required?: boolean;
  yearItemNumber?: number;
  autoComplete?: string;
  isError?: boolean;
  errMessage?: string;
  control?: any;
  name?: any;
  serverErr?: any;
  onKeyDown?: any;
};

export const CustomInputComponent = forwardRef((cprops: any, ref: any) => {
  const fillColor =
    cprops.serverErr || (cprops.errMessage && cprops.isError && !cprops.selected)
      ? "#bc0000"
      : "#007abc";
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxLength = 4;
    if (e.target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
    }
  };
  return (
    <div
      className={`${classes.customInputComponent} ${
        cprops.inputHasValue ? classes.inputHasValue : ""
      }`}
    >
      {/* <div> */}
      <input
        {...cprops}
        ref={ref}
        type="number"
        onInput={handleInput}
        data-testid="InputDatePicker__input"
      />
      <label>Year of Birth of Policy Owner *</label>
      <CalenderIcon fillColor={fillColor} />
    </div>
  );
});

export const InputDatePicker: React.FC<InputDatePickerProps> = forwardRef(
  (
    {
      name,
      isError = false,
      errMessage = "This field is required.",
      selected = null,
      maxDate = null,
      minDate = null,
      onChange = () => {},
      placeholderText = "Year of Birth of Policy Owner *",
      dateFormat = INPUT_DATE_PICKER_FORMAT,
      required = false,
      yearItemNumber = 9,
      showYearPicker = true,
      autoComplete = "off",
      serverErr = "",
      control,
      onChangeRaw = () => {},
      onKeyDown = () => {},
      ...props
    },
    ref: any
  ) => {
    return (
      <div className={classes.mainContainer}>
        <div
          className={`
        ${classes.inputContainer} 
        ${
          serverErr || (errMessage && isError && !selected)
            ? classes["is-invalid"]
            : ""
        } 
        ${selected ? classes.inputHasValue : ""}
        ${classes.inputDropDownContainer} 
      `}
        >
          <DatePicker
            onKeyDown={(e) => {
              // Define allowed keys
              const allowedKeys = [
                "Backspace",
                "ArrowLeft",
                "ArrowRight",
                "ArrowUp",
                "ArrowDown",
                "Delete",
                "Tab",
                "Control",
                "v", // for paste
                "c", // for copy
                "a", // for select all
                "x", // for cut
              ];

              // Check if the key is not allowed and is not a number
              if (!allowedKeys.includes(e.key) && isNaN(Number(e.key))) {
                e.preventDefault();
              }
            }}
            onChangeRaw={onChangeRaw}
            required={required}
            maxDate={maxDate}
            minDate={minDate}
            selected={selected}
            customInput={
              <CustomInputComponent
                inputHasValue={selected}
                serverErr={serverErr}
                isError={isError}
                selected={selected}
                errMessage={errMessage}
                onKeyDown={onKeyDown}
              />
            }
            onChange={onChange}
            autoComplete={autoComplete}
            showYearPicker={showYearPicker}
            dateFormat={dateFormat}
            dateFormatCalendar="yyyy"
            yearItemNumber={yearItemNumber}
            {...props}
          />
        </div>
        {serverErr && (
          <div className={classes["is-error"]}>{serverErr || errMessage}</div>
        )}
        {errMessage && isError && !selected && (
          <div className={classes["is-error"]}>{serverErr || errMessage}</div>
        )}
      </div>
    );
  }
);

// create a custom checkbox component without using input type checkbox

type MetLifeCheckboxProps = {
  label?: string;
  checked?: boolean;
  onChange?: (e: any) => void;
  containerCustomClass?: string;
  style?: React.CSSProperties;
  isError?: boolean;
};

export const MetLifeCheckbox: React.FC<MetLifeCheckboxProps> = ({
  label,
  checked = false,
  isError = false,
  onChange = () => {},
  containerCustomClass,
  style,
  ...props
}) => {
  return (
    <div className={classes.checkboxContainer}>
      <div
        className={`${classes.checkbox} ${checked ? classes.checked : ""} ${
          isError ? classes["is-error"] : ""
        }`}
        role="button"
        onClick={onChange}
      >
        {checked && <CheckboxIcon />}
      </div>
      <div className={classes.label}>{label}</div>
    </div>
  );
};

type MetLifeRadioInputProps = {
  value?: any;
  checked?: boolean;
  defaultChecked?: boolean;
  name?: string;
  label?: string;
  onChange?: (e: any) => void;
  labelCustomClass?: string;
  style?: React.CSSProperties;
  isError?: boolean;
  children?: React.ReactElement;
  disabled?: boolean;
};

export const MetLifeRadioInput: React.FC<MetLifeRadioInputProps> = ({
  checked,
  value = true,
  onChange,
  labelCustomClass,
  style,
  isError,
  defaultChecked = false,
  label = "",
  disabled = false,
  children,
}) => {
  return (
    <label className={`${labelCustomClass} ${classes.radio}`}>
      <div className={classes.radioLabelWrapper}>
        <input
          name="radio"
          onChange={onChange}
          type="radio"
          checked={checked}
          style={style}
          value={value}
          disabled={disabled}
          defaultChecked={defaultChecked}
        />
        {label && <span>{label}</span>}
      </div>

      {children}
    </label>
  );
};

type MetLifeTextAreaProps = {
  style?: React.CSSProperties;
  containerCustomClass?: string;
  isError?: boolean;
  prePopulate?: any;
  inputHasValue?: any;
  placeholder?: string;
  defaultValue?: string;
  errMessage?: any;
  control?: any;
  name?: string;
};

export const MetLifeTextArea: React.FC<MetLifeTextAreaProps> = ({
  style,
  containerCustomClass,
  isError,
  prePopulate,
  inputHasValue,
  placeholder,
  defaultValue,
  errMessage,
  control,
  name,
  ...props
}) => {
  return (
    <div
      className={`${classes.mainContainer} ${containerCustomClass}`}
      style={style}
    >
      <div
        className={`
                ${classes.inputContainer} 
                ${!!prePopulate && classes.hasPrepopulateItem}
                ${isError ? classes["is-invalid"] : ""} 
                ${!!inputHasValue ? classes.inputHasValue : ""}
              `}
      >
        <textarea {...control.register(name)} defaultValue={defaultValue}></textarea>
        <label>{placeholder}</label>
      </div>
      {errMessage && isError && (
        <div className={classes["is-error"]}>{errMessage}</div>
      )}
    </div>
  );
};

type MetLifeSelectProps = {
  label?: string;
  value?: string;
  onSelect?: (e: any) => void;
  type?: string;
  required?: boolean | false;
  placeholder?: string;
  isError?: boolean;
  errMessage?: any;
  optionsList?: Array<any>;
  identifier?: string;
  containerCustomClass?: string;
  wrapperCustomClass?: string;
  style?: React.CSSProperties;
  renderSelectedComponent?: () => React.ReactNode | undefined;
  renderItemComponent?: (item: any) => React.ReactNode | undefined;
  inputHasValue?: any;
  control?: any;
  defaultValue?: any;
  name?: string;
};

export const MetLifeSelect: React.FC<MetLifeSelectProps> = ({
  control,
  label,
  value = "",
  onSelect = () => {},
  optionsList = [],
  identifier = "value",
  type = "text",
  placeholder = "",
  required,
  isError = false,
  errMessage = "This field is invalid.",
  style,
  renderSelectedComponent,
  renderItemComponent,
  containerCustomClass,
  wrapperCustomClass,
  inputHasValue,
  defaultValue,
  name,
  ...props
}) => {
  return (
    <div
      className={`${classes.mainContainer} ${containerCustomClass}`}
      style={style}
    >
      <div
        className={`
                ${classes.inputContainer} 
                ${isError ? classes["is-invalid"] : ""} 
                ${!!inputHasValue ? classes.inputHasValue : ""}
              `}
      >
        <select {...control.register(name)}>
          {optionsList &&
            optionsList.map((ol: any) => (
              <option
                key={ol?.id}
                value={ol[identifier]}
                className={value === ol[identifier] ? classes.active : ""}
              >
                {ol[identifier]}
              </option>
            ))}
        </select>
        <label>{placeholder}</label>
      </div>
      {errMessage && isError && (
        <div className={classes["is-error"]}>{errMessage}</div>
      )}
    </div>
  );
};
