export const termsAndConditions = [
  {
    id: "line-01",
    text: "Upon successful completion of each Payment transaction, a System generated Premium/Money Receipt (without signature) will be displayed on screen as a proof of Payment with save and print option. Same will be sent to the Policy-holder’s designated email address provided at the time of processing of this Premium Payment and or the email address that you provided at the time of purchasing the policy. MetLife reserves the right to use/store/share your / payor’s email ID in the MetLife Policy Admin System for future necessity/reference.",
  },
  {
    id: "line-02",
    text: "The Receipt shall not be binding upon MetLife unless the transacted amount gets credited into MetLife’s designated Bank account. Upon getting credited amount to MetLife’s designated Bank account, a notification SMS (acknowledging the receipt of payment) to be sent to the Policy-holder’s registered mobile number.",
  },
  {
    id: "line-03",
    text: "Not with standing, under any circumstances, MetLife will neither be liable for any input error (e.g., erroneous information, amount, typographical error etc.) by Payor nor for any fraudulent or unauthorized use of the online payment by any other person whatsoever.",
  },
  {
    id: "line-04",
    text: "For further queries or services and assistance in future, kindly feel free to call 15970166 between 9:00 AM to 5:30 PM, except holidays. You may also write to us through Contact Us section of our website metlife.com.np. MetLife reserves the right to amend, change, alter and modify the terms and conditions at any time without prior notice.",
  },
];
