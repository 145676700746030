import { BASE_API_URL } from "./base-api-url";
//payment apis
export const PAYMENT_SERVICE_BASE_URI_V2 = `${BASE_API_URL}/opps-payment/v2`;
export const PAYMENT_SERVICE_BASE_URI = `${BASE_API_URL}/opps-payment/v2`;
export const POST_INITIATE_PAYMENT_API = `${PAYMENT_SERVICE_BASE_URI}/payment/initiate`;
export const PUT_START_PAYMENT_API = `${PAYMENT_SERVICE_BASE_URI}/payment/start`;
export const POST_CREATE_PAYMENT_API = `${PAYMENT_SERVICE_BASE_URI}/payment/details`;
export const GET_PAYMENT_OPTIONS = `${PAYMENT_SERVICE_BASE_URI}/payment/options`;
export const PUT_TERMS_CONDITIONS_API = `${PAYMENT_SERVICE_BASE_URI}/payment/terms-and-conditions`;
export const PUT_CONFIRM_PAYMENT_API = `${PAYMENT_SERVICE_BASE_URI}/confirm-payment`;
export const POST_FINISH_API = `${PAYMENT_SERVICE_BASE_URI}/payment/finish`;

export const GET_POLICY_DETAILS_API = `${PAYMENT_SERVICE_BASE_URI}`;
export const GET_POLICY_DETAILS_API_V2 = `${PAYMENT_SERVICE_BASE_URI_V2}`;
export const UPDATE_MOBILE_NUMBER_API = `${PAYMENT_SERVICE_BASE_URI}/payment/update-mobile`;
export const DOWNLOAD_RECEIPT = `${PAYMENT_SERVICE_BASE_URI}/payment/receipt`;