import React, { useEffect, useState } from "react";
import classes from "./MetlifeOTPInput.module.css";

const MetlifeOTPInput = ({
  isError,
  onChange = (v: any) => {},
  errMessage,
}: {
  isError?: boolean;
  onChange?: (otp: string) => void;
  errMessage?: any;
}) => {
  const [otp, setOtp] = useState<any>({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });

  useEffect(() => {
    onChange(otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4 + otp.otp5 + otp.otp6);
  }, [otp]);

  const handleKeyDown = (
    event: React.KeyboardEvent,
    prevInputKey = "",
    currentInputKey = ""
  ) => {
    if (event.key === "Backspace") {
      setTimeout(() => {
        document.getElementById(prevInputKey)?.focus();
      }, 80);
    }

    if (!/^\d/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const handleOnChange = (inputKey: any, value: any, nextInput = "") => {
    setOtp({
      ...otp,
      [inputKey]: value,
    });
    if (value) {
      document.getElementById(nextInput)?.focus();
    }
  };

  return (
    <div className={classes?.container}>
      <div
        className={`${classes?.otpInputsContainer} ${
          isError ? classes["is-invalid"] : ""
        }`}
      >
        <input
          type="text"
          id="otp1"
          data-testid="metlife-input-otp1"
          autoComplete="off"
          maxLength={1}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => handleOnChange("otp1", e.target.value, "otp2")}
        />
        <input
          type="text"
          id="otp2"
          data-testid="metlife-input-otp2"
          autoComplete="off"
          maxLength={1}
          onKeyDown={(e) => handleKeyDown(e, "otp1", "otp2")}
          onChange={(e) => handleOnChange("otp2", e.target.value, "otp3")}
        />
        <input
          type="text"
          id="otp3"
          data-testid="metlife-input-otp3"
          autoComplete="off"
          maxLength={1}
          onKeyDown={(e) => handleKeyDown(e, "otp2", "otp4")}
          onChange={(e) => handleOnChange("otp3", e.target.value, "otp4")}
        />
        <input
          type="text"
          id="otp4"
          data-testid="metlife-input-otp4"
          autoComplete="off"
          maxLength={1}
          onKeyDown={(e) => handleKeyDown(e, "otp3", "otp5")}
          onChange={(e) => handleOnChange("otp4", e.target.value, "otp5")}
        />
        <input
          id="otp5"
          data-testid="metlife-input-otp5"
          type="text"
          autoComplete="off"
          maxLength={1}
          onKeyDown={(e) => handleKeyDown(e, "otp4", "otp5")}
          onChange={(e) => handleOnChange("otp5", e.target.value, "otp6")}
        />
        <input
          id="otp6"
          data-testid="metlife-input-otp6"
          type="text"
          autoComplete="off"
          maxLength={1}
          onKeyDown={(e) => handleKeyDown(e, "otp5", "otp6")}
          onChange={(e) => handleOnChange("otp6", e.target.value)}
        />
      </div>
      {isError && <div className={classes["is-error"]}>{errMessage}</div>}
    </div>
  );
};

MetlifeOTPInput.propTypes = {};

export default MetlifeOTPInput;
