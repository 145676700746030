import {
  IMEPAY_MODULE_KEY,
  IMEPAY_PASSWORD,
  IMEPAY_USERNAME,
  KHALTI_SECRET_KEY,
} from "./secret-keys";

const config = {
  inactivityTimeMinutes: 15,
  inactivityTimeIntervalInSeconds: 1,
  countdownTimerSeconds: 60,
  sessionCountdownIntervalSeconds: 10,
  otpResendTimeInSeconds: 120,
  OTPResendMaxLimitCount: 5,
  toastMessageDurationInSeconds: 5,
  khaltiPayAuthKey: KHALTI_SECRET_KEY,
  imePayUsername: IMEPAY_USERNAME,
  imePayPassword: IMEPAY_PASSWORD,
  imePayModuleKey: IMEPAY_MODULE_KEY,
};

export default config;
