import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Card } from "@metlife-one-opps/card";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import { MetLifeGradientRow } from "@metlife-one-opps/metlife-gradient-row";
import { MetLifeInput } from "@metlife-one-opps/metlife-input";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  CANCEL_SI_CONFIRM_BTN_TEXT,
  CANCEL_SI_DECLINE_BTN_TEXT,
  CANCEL_SI_DESC,
  CANCEL_SI_HEADING,
  CANCEL_SI_SUCCESS_TOAST_MSG,
  MANDATORY,
  OPS_CURRENCY,
  SI_FAILIURE_URL,
  SI_SUCCESS_URL,
  VALID_EMAIL,
  VALID_MOBILE_NUMBER,
} from "../../constants";
import { base62encode, formatAmount } from "@metlife-one-opps/utils";
import {
  cancelStandingInstructions,
  getStandingInstructions,
  initiateStandingInstructions,
  viewStandingInstructionsStatus,
} from "@metlife-one-opps/services";
import moment from "moment";
import { useLoader } from "@metlife-one-opps/hooks";
import { AlertPopup } from "@metlife-one-opps/alert-popup";
import { ToastMessage } from "./ToastMessage/ToastMessage";

const schema = yup.object().shape({
  emailId: yup.string().email(VALID_EMAIL).required(MANDATORY),
  mobileNumber: yup.string().required(MANDATORY).min(10, VALID_MOBILE_NUMBER),
});

export const StandingInstructions = ({ state }: any) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const { policy, isStandingInstructionsSet, standingInstructionStatus } =
    state ?? {};
  const [mandateInfo, setMandateInfo]: any = useState({});
  const [showCancellationAlert, setShowCancellationAlert] = useState<boolean>(false);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [isMandateSet, setIsMandateSet] = useState<boolean>(false);
  const [mandateUrl, setMandateUrl]: any = useState("");
  const [connectIpsData, setConnectIpsData] = useState<any>({});

  const onSubmit = async (data: any) => {
    const { emailId, mobileNumber } = data;
    try {
      setLoading(true);
      const res = await initiateStandingInstructions({
        policyNumber: policy?.policyNumber,
        email: emailId,
        msisdn: mobileNumber,
        successUrl: base62encode(
          `${SI_SUCCESS_URL}&policyNumber=${policy?.policyNumber}`
        ),
        failureUrl: base62encode(
          `${SI_FAILIURE_URL}&policyNumber=${policy?.policyNumber}`
        ),
      });
      setLoading(false);
      if (res?.status === 200) {
        const responseData = res?.data;
        setConnectIpsData(JSON.parse(responseData.paymentJson));
        setMandateUrl(responseData?.paymentUrl);
        setTimeout(() => {
          document.getElementById("payment-overview-button")?.click();
        }, 300);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getMandateInfo = async () => {
    try {
      setLoading(true);
      const res = await viewStandingInstructionsStatus(policy.policyNumber);
      setLoading(false);
      if (res?.status === 200) {
        setMandateInfo({
          ...res?.data,
          email: res?.data?.email?.replace(/ /g, ""),
          msisdn: res?.data?.msisdn?.replace(/ /g, ""),
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getStandingInstructionDetails = async () => {
    try {
      setLoading(true);
      const res = await getStandingInstructions(policy.policyNumber);
      setLoading(false);
      if (res?.status === 200) {
        setMandateInfo({
          ...res?.data,
          email: res?.data?.email?.replace(/ /g, ""),
          msisdn: res?.data?.msisdn?.replace(/ /g, ""),
        });
        setValue("emailId", res?.data?.email);
        setValue("mobileNumber", res?.data?.msisdn);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const confirmSICancel = async () => {
    setShowCancellationAlert(true);
  };

  const cancelSI = async () => {
    try {
      setLoading(true);
      const res = await cancelStandingInstructions(policy.policyNumber);
      setLoading(false);
      if (res?.status === 200) {
        setShowSuccessToast(true);
        setShowCancellationAlert(false);
        setIsMandateSet(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (policy && policy.hasOwnProperty("policyNumber")) {
      setIsMandateSet(isStandingInstructionsSet);
      if (isStandingInstructionsSet) {
        getMandateInfo();
      } else {
        getStandingInstructionDetails();
      }
    }
  }, [standingInstructionStatus, isStandingInstructionsSet, policy]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="StandingInstructions__mandate-form"
      >
        <div className="ConfirmPayment_heading">
          <MetLifeGradientRow />
          <div className="ConfirmPayment_page-inner-header">
            <h1>{isMandateSet ? "My" : "Setup"} Standing Instruction</h1>
            {isMandateSet && (
              <button
                type="button"
                role="link"
                className="btn-SI-cancellation"
                data-testid="cancelSI-Btn"
                onClick={confirmSICancel}
              >
                Cancel Standing Instruction
              </button>
            )}
          </div>
        </div>
        {Object.keys(connectIpsData).length > 0 && (
          <form
            action={mandateUrl}
            method={"POST"}
            data-testid="payment-gateway-form-data"
          >
            {Object.keys(connectIpsData).map((key: any) => (
              <input
                type="hidden"
                data-testid={`cips-form-data-${key}`}
                key={key}
                name={key}
                id={key}
                value={connectIpsData[key]}
              />
            ))}
            <button id="payment-overview-button" style={{ display: "none" }} />
          </form>
        )}

        <div className="ConfirmPayment_cards">
          <div className="cardComponent">
            <Card>
              <div className="StandingInstructions__mandate-information">
                {isStandingInstructionsSet && (
                  <div className="row">
                    <div className="column-1">
                      <div className="label">Email ID</div>
                      <div className="value">
                        <div>{mandateInfo?.email ?? "N/A"}</div>
                      </div>
                    </div>
                    <div className="column-2">
                      <div className="label">Phone Number</div>
                      <div className="value">
                        <div>{mandateInfo?.msisdn ?? "N/A"}</div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="column-1">
                    <div className="label">Amount</div>
                    <div className="value">
                      <div>
                        {mandateInfo?.paymentAmount
                          ? `${OPS_CURRENCY} ${formatAmount(
                              Number(mandateInfo?.paymentAmount.split(" ")[1])
                            )}`
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="column-2">
                    <div className="label">Mandate Start Date</div>
                    <div className="value">
                      <div>
                        {mandateInfo?.startDateTime
                          ? moment(mandateInfo?.startDateTime).format("DD/MM/YYYY")
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="column-1">
                    <div className="label">Mandate Expiry Date</div>
                    <div className="value">
                      <div>
                        {mandateInfo?.endDateTime
                          ? moment(mandateInfo?.endDateTime).format("DD/MM/YYYY")
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="column-2">
                    <div className="label">Debit Type</div>
                    <div className="value capitalize-text">
                      <div>
                        {mandateInfo?.paymentType
                          ? mandateInfo?.paymentType.toLowerCase()
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="column-1">
                    <div className="label">Frequency</div>
                    <div className="value capitalize-text">
                      <div>
                        {mandateInfo?.frequency
                          ? mandateInfo?.frequency.toLowerCase().replace(/_/g, " ")
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {!isStandingInstructionsSet && (
            <div className="cardComponent">
              <Card>
                <div className="StandingInstructions__mandate-information">
                  <div className="row">
                    <div className="column-1">
                      <div className="input-container">
                        <h4>Please provide your Email ID</h4>
                        <Controller
                          name="emailId"
                          control={control}
                          render={({ field }) => (
                            <MetLifeInput
                              data-testid="MetLifeInput__text__emailId"
                              defaultValue={mandateInfo?.email ?? ""}
                              control={control}
                              inputHasValue={!!field.value || mandateInfo?.email}
                              name={"emailId"}
                              placeholder="Email ID*"
                              type="text"
                              isError={!!errors?.emailId?.message}
                              errMessage={errors?.emailId?.message}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="column-2">
                      <div className="input-container">
                        <h4>Please provide your mobile number</h4>
                        <Controller
                          name="mobileNumber"
                          control={control}
                          render={({ field }) => (
                            <MetLifeInput
                              data-testid="MetLifeInput__text__mobileNumber"
                              defaultValue={mandateInfo?.msisdn ?? ""}
                              prePopulate={"+977"}
                              control={control}
                              inputHasValue={!!field.value || mandateInfo?.msisdn}
                              name={"mobileNumber"}
                              placeholder="Mobile Number*"
                              type="phone"
                              maxLength={10}
                              onPaste={(e) => {
                                trigger("mobileNumber", { shouldFocus: true });
                              }}
                              isError={!!errors?.mobileNumber?.message}
                              errMessage={errors?.mobileNumber?.message}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
        <div className="StandingInstructions__bottom-notice">
          *The Premium deduction will start from the next due date of your policy
        </div>
        <div className="ConfirmPayment_button">
          <MetLifeButton
            data-testid="StandingInstructions_back"
            type="button"
            variant="secondary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </MetLifeButton>

          {!isStandingInstructionsSet && (
            <MetLifeButton data-testid="StandingInstructions_next">
              Next
            </MetLifeButton>
          )}
        </div>
      </form>
      {state && state?.siCancelTest && (
        //testing si cancelation request --testing--only
        <>
          <MetLifeButton
            data-testid="cancelSI-Btn-API"
            onClick={cancelSI}
            variant="secondary"
          />

          <MetLifeButton
            data-testid="show-si-toast-message"
            onClick={() => setShowSuccessToast(true)}
            variant="secondary"
          />
        </>
      )}
      <AlertPopup
        alertWrapperCustomClass="cf-custom-modal-wrapper-bottom-sheet"
        heading={CANCEL_SI_HEADING}
        showPrimaryBtn={true}
        showSecodaryBtn={true}
        primaryBtnText={CANCEL_SI_CONFIRM_BTN_TEXT}
        secondaryBtnText={CANCEL_SI_DECLINE_BTN_TEXT}
        description={CANCEL_SI_DESC}
        isVisible={showCancellationAlert}
        onCloseAlert={() => setShowCancellationAlert(false)}
        onPressSecondary={() => setShowCancellationAlert(false)}
        onPressPrimary={cancelSI}
      />
      {showSuccessToast && (
        <ToastMessage
          type="success"
          isVisible={showSuccessToast}
          onClose={() => {
            navigate("/payment-type");
            setShowSuccessToast(false);
          }}
          message={CANCEL_SI_SUCCESS_TOAST_MSG}
        />
      )}
    </div>
  );
};
