import React, { useEffect, useMemo, useState } from "react";
import { Card } from "@metlife-one-opps/card";
import { useLoader } from "@metlife-one-opps/hooks";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import { MetLifeGradientRow } from "@metlife-one-opps/metlife-gradient-row";
import { MetLifeRadioInput } from "@metlife-one-opps/metlife-input";
import { PageContainer } from "@metlife-one-opps/page-container";
import {
  createPaymentService,
  getPaymentOptions,
  getStandingInstructionsStatus,
} from "@metlife-one-opps/services";
import { jwtDecode } from "jwt-decode";
import { useLocation, useNavigate, useBlocker } from "react-router-dom";
import { ONLINE_PAYMENT, STANDING_INSTRUCTION } from "../../../constants";
import { paymentTypeOptionsLabels } from "../../../data";
import "./PaymentType.css";

const PaymentType = () => {
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [policyNumber, setPolicyNumber] = useState(state?.policyNumber);
  const [birthYear, setBirthYear] = useState(state?.birthYear);
  const redirection = state?.LogoutRedirection ?? false;
  const [policyData, setPolicyData] = useState<any>("");
  const [selectedTab, setSelectedTab] = useState(ONLINE_PAYMENT);
  const [standingInstructionStatus, setStandingInstructionStatus] = useState(false);
  const [selectPaymentType, setSelectPaymentType] = useState<any>({});
  const [isPremiumPaymentAvailable, setIsPremiumPaymentAvailable] =
    useState<boolean>(false);
  window.document.title = "Online Premium Payment - Metlife Nepal OPP";
  interface CustomJwtPayload {
    sub: string;
    aud: string;
    nbf: number;
    iss: string;
    sessionId: string;
  }

  useEffect(() => {
    if (redirection) {
      setLoading(true);
      fetchDetails();
    }
  }, [redirection]);

  let value = "/payment-type";
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname === value &&
      nextLocation.pathname ===
        (localStorage.getItem("@user-access") === null ? "" : "/")
  );

  useEffect(() => {
    if (localStorage.getItem("@user-access")) {
      if (blocker && blocker.state === "blocked") {
        blocker.reset();
      }
    }
  }, [blocker]);

  const getTransactionIdFromToken = () => {
    let transactionId = "";
    const tokenString = localStorage.getItem("@user-access");
    const AccessToken = tokenString ? JSON.parse(tokenString) : {};
    if (AccessToken?.token) {
      const decode = jwtDecode<CustomJwtPayload>(AccessToken.token);
      transactionId = decode?.sessionId ?? "";
    }
    return transactionId;
  };

  const fetchDetails = async () => {
    const sessionId = getTransactionIdFromToken();
    try {
      const res = await getPaymentOptions(sessionId);
      if (res.status === 200) {
        setPolicyData(res?.data.data);
        setBirthYear(res.data.data.yearOfBirth);
        setPolicyNumber(res.data.data.policyNumber);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await createPaymentService({
        paymentType: selectPaymentType.value,
        transactionId: getTransactionIdFromToken(),
      });
      if (res?.status === 200) {
        const policyInformation = res?.data?.data;
        navigate("/confirm-payment", {
          state: {
            policy: { policyNumber: policyNumber, ...policyInformation },
            selectedPayment: selectPaymentType,
            paymentType: selectedTab,
            paymentId: res?.data?.data?.transactionId,
            isStandingInstructionsSet: !!policyData?.isStandingInstructionsSet,
          },
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error while starting the payment.", error);
    }

    return false;
  };

  const policyPaymentTypes = useMemo(() => {
    return policyData && policyData?.paymentTypes
      ? policyData?.paymentTypes.map((ptype: any, idx: number) => ({
          id: idx + 1,
          label: paymentTypeOptionsLabels[ptype],
          value: ptype.toLowerCase(),
        }))
      : [];
  }, [policyData]);

  useEffect(() => {
    if (policyPaymentTypes) {
      setIsPremiumPaymentAvailable(
        policyPaymentTypes.some((ptype: any) => ptype.value === "premium")
      );
      setSelectPaymentType(policyPaymentTypes[0]);
    }
  }, [policyPaymentTypes]);

  const getSI_Status = async () => {
    setLoading(true);
    try {
      const standingInstResponse = await getStandingInstructionsStatus(policyNumber);
      if (standingInstResponse?.status === 200) {
        setStandingInstructionStatus(
          standingInstResponse?.data?.haveStandingInstruction
        );
      }
    } catch (error) {
      setLoading(false);
      console.log("Error while getting standing instruction status", error);
    }
  };

  useEffect(() => {
    if (!!policyData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [policyData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let timeout = setTimeout(() => {
      if (!redirection) {
        fetchDetails();
      }
      getSI_Status();
    }, 200);
    return () => clearTimeout(timeout);
  }, []);

  const standingInstructionHandler = () => {
    navigate("/confirm-payment", {
      state: {
        policy: { ...policyData, amount: selectPaymentType?.amount },
        selectedPayment: selectPaymentType,
        paymentType: selectedTab,
        isStandingInstructionsSet: !!standingInstructionStatus,
      },
    });
  };
  return (
    <PageContainer
      pageTitle="Online Premium Payment"
      pageSubTitle="Facilitating convenient and secure transactions for insurance premiums over the internet."
    >
      <div className="PaymentType__mainContainer">
        <form onSubmit={handleSubmit} data-testid="PaymentType__form-submit">
          <div className="PaymentType__heading">
            <MetLifeGradientRow />
            <h1>Purpose of Payment: {selectPaymentType?.label}</h1>
          </div>
          <Card customClass="PaymentType__card-2">
            <div className="PaymentType_cardContainer">
              <div className="PaymentType_cardHeading">
                <h3>Policy Details</h3>
              </div>
              <div className="PaymentType_cardDetails">
                <table>
                  <tbody>
                    <tr className="PaymentType_cardDetailsRow">
                      <td width={"120"}>
                        <strong>Policy Owner</strong>
                      </td>
                      <td>:</td>
                      <td>{policyData?.ownerName}</td>
                    </tr>
                    <tr className="PaymentType_cardDetailsRow">
                      <td width={"120"}>
                        <strong>Policy Number</strong>
                      </td>
                      <td>:</td>
                      <td>{policyData?.policyNumber ?? policyNumber}</td>
                    </tr>
                    <tr className="PaymentType_cardDetailsRow">
                      <td width={"120"}>
                        <strong>Year of Birth</strong>
                      </td>
                      <td>:</td>
                      <td>{policyData?.yearOfBirth ?? birthYear} </td>
                    </tr>
                  </tbody>
                </table>
                <a
                  data-testid="kyc-update-link"
                  className="PaymentType__kycLink"
                  target="_blank"
                  href="https://epay.metlife.com.np/kycupdate/"
                >
                  Update your KYC Information
                </a>
              </div>
            </div>
          </Card>
          <Card customClass="PaymentType__card-2">
            <div className="PaymentType__tab-content">
              <div className="PaymentType__tabs-container">
                <div
                  className={`PaymentType__tab ${
                    selectedTab === ONLINE_PAYMENT ? "selected" : ""
                  }`}
                  onClick={() => setSelectedTab(ONLINE_PAYMENT)}
                >
                  Payment Type
                </div>

                <div
                  className={`PaymentType__tab 
                      ${isPremiumPaymentAvailable ? "" : "disabled"}
                      ${selectedTab === STANDING_INSTRUCTION ? "selected" : ""}`}
                  onClick={() => {
                    if (isPremiumPaymentAvailable) {
                      setSelectPaymentType(policyPaymentTypes[0]);
                      setSelectedTab(STANDING_INSTRUCTION);
                    }
                  }}
                >
                  Standing Instruction
                </div>

                <span
                  className={`slider ${
                    selectedTab === STANDING_INSTRUCTION ? "opt-2" : ""
                  }`}
                ></span>
              </div>
              <div
                className={`tab-content ${
                  selectedTab === STANDING_INSTRUCTION ? "active" : ""
                }`}
              >
                {selectedTab === ONLINE_PAYMENT && (
                  <div className="payment-type-radios">
                    <div className="tab-content-heading">
                      Select your payment purpose and pay through online payment
                      gateway.
                    </div>
                    <div className="radio-group-payment-types">
                      <AllowedPaymentTypes
                        data={policyPaymentTypes}
                        selected={selectPaymentType}
                        onSelect={(v: any) => setSelectPaymentType(v)}
                      />
                    </div>
                    <div className="PaymentType_button sm">
                      <MetLifeButton data-testid="payment-type-next-button">
                        Next
                      </MetLifeButton>
                    </div>
                  </div>
                )}

                {selectedTab === STANDING_INSTRUCTION &&
                  isPremiumPaymentAvailable && (
                    <div className="payment-type-radios">
                      <div className="tab-content-heading">
                        Setup a recurring payment through your bank account.
                      </div>

                      {!!standingInstructionStatus === false && (
                        <div className="radio-group-payment-types">
                          <AllowedPaymentTypes
                            disableAll={true}
                            data={policyPaymentTypes.filter(
                              (p: any) => p.value === "premium"
                            )}
                            selected={selectPaymentType}
                            onSelect={(v: any) => setSelectPaymentType(v)}
                          />
                        </div>
                      )}

                      <div className="PaymentType_button md">
                        {!!standingInstructionStatus ? (
                          <MetLifeButton
                            data-testid="PaymentType__view-standing-instruction-button"
                            type="button"
                            onClick={standingInstructionHandler}
                            variant="secondary"
                          >
                            View Standing Instruction
                          </MetLifeButton>
                        ) : (
                          <MetLifeButton
                            data-testid="PaymentType__setup-standing-instruction-button"
                            type="button"
                            onClick={standingInstructionHandler}
                          >
                            Setup Standing Instruction
                          </MetLifeButton>
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </Card>
        </form>
      </div>
    </PageContainer>
  );
};

const AllowedPaymentTypes = ({
  data,
  onSelect,
  selected,
  disableAll = false,
}: any) => (
  <>
    {data &&
      data?.map((pto: any) => (
        <MetLifeRadioInput
          key={pto?.id}
          disabled={disableAll}
          value={pto?.value}
          label={pto?.label}
          data-testid={`payment-type-${pto?.value}`}
          checked={selected?.id === pto?.id}
          onChange={() => onSelect(pto)}
        />
      ))}
  </>
);

export default PaymentType;
