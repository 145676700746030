import React, { useEffect, useState } from "react";
import appConfig from "@metlife-one-opps/app/src/config";
import { CheckCircle, InfoCircleIcon } from "../../../assets";
import { CloseIcon } from "@metlife-one-opps/page-container";
import classes from "./ToastMessage.module.css";

interface ToastMessageProps {
  duration?: number;
  type?: "info" | "error" | "success" | "warning";
  message?: string;
  isVisible?: boolean;
  onClose?: () => void;
}

const IconLeft = ({ type }: { type: "info" | "error" | "success" | "warning" }) => {
  switch (type) {
    case "info":
      return <InfoCircleIcon />;
    case "error":
      return <InfoCircleIcon />;
    case "warning":
      return <InfoCircleIcon />;
    default:
      return <CheckCircle />;
  }
};

export const ToastMessage = ({
  onClose = () => {},
  isVisible = false,
  duration = appConfig.toastMessageDurationInSeconds || 1,
  type = "success",
  message = "success message delivered!",
}: ToastMessageProps) => {
  const [timer, setTimer] = useState(duration);
  const [showToast, setShowToast] = useState(isVisible);

  useEffect(() => {
    setShowToast(isVisible);
    let timerInterval: NodeJS.Timeout = setInterval(() => {}, 0);
    if (isVisible) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timerInterval);
            setShowToast(false);
            onClose();
            return 0;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }
    return () => {
      clearInterval(timerInterval);
    };
  }, [isVisible]);

  useEffect(() => {
    setTimer(duration);
  }, [duration]);

  return (
    <div
      className={`${classes.toast} ${classes[type]} ${
        showToast ? classes.show : ""
      }`}
      id="toast-message-main"
      data-testid="toast-message-main"
    >
      <div className={classes.content}>
        <input
          type="hidden"
          data-testid="toast-message-status"
          value={showToast.toString()}
        />
        <div className={classes.iconmessage}>
          <div className="pre-icon">
            <IconLeft data-testid="toast-icon" type={type} />
          </div>
          <div className={classes.message}>{message}</div>
        </div>
        <div
          data-testid="toast-icon"
          className={classes.closeIcon}
          role="button"
          onClick={(e) => {
            setShowToast(false);
          }}
        >
          <CloseIcon height={16} width={16} />
        </div>
      </div>
    </div>
  );
};
