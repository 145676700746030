import { refreshToken as requestRefereshToken } from "@metlife-one-opps/services";
import axios from "axios";
import qs from "qs";
import { eventEmitter } from "../events";
const axiosPrivate = axios.create({});

const getAccessToken = () => {
  const accessDataJSONString: any = localStorage.getItem("@user-access");
  const accessData = JSON.parse(accessDataJSONString);
  return {
    access_token: accessData?.token ?? "",
    refresh_token: accessData?.refresh_token ?? "",
  };
};

axiosPrivate.interceptors.request.use(
  async (config) => {
    let ad = getAccessToken();
    let accessToken = ad?.access_token ?? "";
    config.headers["Authorization"] = accessToken ? `Bearer ${accessToken}` : "";
    config.headers["X-Bearer-Issuer"] =
      window?.__RUNTIME_CONFIG__?.REACT_APP_API_ISSUER;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    let accessData = getAccessToken();
    let refreshToken = accessData?.refresh_token ?? "";
    let access_token = accessData?.access_token ?? "";

    // access_token is expired after 15 minutes.
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      let data = qs.stringify({
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      });

      await requestRefereshToken(data)
        .then((response) => {
          access_token = response.data.access_token;
          localStorage.setItem(
            "@user-access",
            JSON.stringify({
              ...accessData,
              token: response.data.access_token,
            })
          );
        })
        .catch((error) => {
          eventEmitter.emit("@user-session-out");
        });

      axiosPrivate.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${access_token}`;

      return axiosPrivate(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosPrivate;
