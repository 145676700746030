import { DOWNLOAD_RECEIPT } from "./constants";
import axios from "axios";

// This is temporary workaround --should be removed before prod
export const receiptGenerate = async (
  payload: { paymentId: string },
  config?: any
) => {
  const res = await axios.get(
    `${DOWNLOAD_RECEIPT}?transactionId=${payload.paymentId}`,
    config
  );
  return res;
};
