import React from "react";

const MinusIcon = ({ height = 2, width = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 16 2"
      fill="none"
    >
      <rect width="16" height="2" fill="#007ABC" />
    </svg>
  );
};

const PlusIcon = ({ height = 16, width = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 0H7V7H0V9H7V16H9V9H16V7H9V0Z"
      fill="#007ABC"
    />
  </svg>
);

const InfoCircleIcon = ({ fillColor = "currentColor", height = 21, width = 21 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M10.5 15.5C10.7833 15.5 11.0208 15.4042 11.2125 15.2125C11.4042 15.0208 11.5 14.7833 11.5 14.5C11.5 14.2167 11.4042 13.9792 11.2125 13.7875C11.0208 13.5958 10.7833 13.5 10.5 13.5C10.2167 13.5 9.97917 13.5958 9.7875 13.7875C9.59583 13.9792 9.5 14.2167 9.5 14.5C9.5 14.7833 9.59583 15.0208 9.7875 15.2125C9.97917 15.4042 10.2167 15.5 10.5 15.5ZM9.5 11.5H11.5V5.5H9.5V11.5ZM10.5 20.5C9.11667 20.5 7.81667 20.2375 6.6 19.7125C5.38333 19.1875 4.325 18.475 3.425 17.575C2.525 16.675 1.8125 15.6167 1.2875 14.4C0.7625 13.1833 0.5 11.8833 0.5 10.5C0.5 9.11667 0.7625 7.81667 1.2875 6.6C1.8125 5.38333 2.525 4.325 3.425 3.425C4.325 2.525 5.38333 1.8125 6.6 1.2875C7.81667 0.7625 9.11667 0.5 10.5 0.5C11.8833 0.5 13.1833 0.7625 14.4 1.2875C15.6167 1.8125 16.675 2.525 17.575 3.425C18.475 4.325 19.1875 5.38333 19.7125 6.6C20.2375 7.81667 20.5 9.11667 20.5 10.5C20.5 11.8833 20.2375 13.1833 19.7125 14.4C19.1875 15.6167 18.475 16.675 17.575 17.575C16.675 18.475 15.6167 19.1875 14.4 19.7125C13.1833 20.2375 11.8833 20.5 10.5 20.5Z"
        fill={fillColor}
      />
    </svg>
  );
};

const CheckCircle = ({ height = 20, width = 20, fillColor = "#00ACA0" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M8.6 14.6L15.65 7.55L14.25 6.15L8.6 11.8L5.75 8.95L4.35 10.35L8.6 14.6ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z"
      fill={fillColor}
    />
  </svg>
);

const CheckboxIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
  >
    <path
      d="M6.54998 12.9996L0.849976 7.29961L2.27498 5.87461L6.54998 10.1496L15.725 0.974609L17.15 2.39961L6.54998 12.9996Z"
      fill="#333333"
    />
  </svg>
);

const PencilIcon = ({ height = 19, width = 19, fillColor = "#007ABC" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      d="M0.331299 18.3828V14.1328L13.5313 0.957812C13.7313 0.774479 13.9521 0.632812 14.1938 0.532813C14.4355 0.432813 14.6896 0.382812 14.9563 0.382812C15.223 0.382812 15.4813 0.432813 15.7313 0.532813C15.9813 0.632812 16.198 0.782813 16.3813 0.982813L17.7563 2.38281C17.9563 2.56615 18.1021 2.78281 18.1938 3.03281C18.2855 3.28281 18.3313 3.53281 18.3313 3.78281C18.3313 4.04948 18.2855 4.30365 18.1938 4.54531C18.1021 4.78698 17.9563 5.00781 17.7563 5.20781L4.5813 18.3828H0.331299ZM14.9313 5.18281L16.3313 3.78281L14.9313 2.38281L13.5313 3.78281L14.9313 5.18281Z"
      fill={fillColor}
    />
  </svg>
);

export {
  MinusIcon,
  PlusIcon,
  InfoCircleIcon,
  CheckCircle,
  CheckboxIcon,
  PencilIcon,
};
