import { yupResolver } from "@hookform/resolvers/yup";
import { AlertPopup } from "@metlife-one-opps/alert-popup";
import { Card } from "@metlife-one-opps/card";
import { useLoader } from "@metlife-one-opps/hooks";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import { MetLifeGradientRow } from "@metlife-one-opps/metlife-gradient-row";
import { InputDatePicker, MetLifeInput } from "@metlife-one-opps/metlife-input";
import { CloseIcon, PageContainer } from "@metlife-one-opps/page-container";
import { generateToken, login } from "@metlife-one-opps/services";
import { getCurrentDate, getSubtractedDate } from "@metlife-one-opps/utils";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { InfoCircleIcon } from "../../../assets";
import {
  BAD_REQUEST,
  GENERAL_SERVER_ERROR,
  INACTIVITY_TIMEOUT_TOAST_MESSAGE,
  OTP001,
  SESSION_TIMEOUT_TOAST_MESSAGE,
  VALID_DETAILS,
  otpStatus,
  serverErrorHeadings,
} from "../../../constants";
import "./PolicyDetails.css";
import { policyDetailSchema } from "./policy-detail-schema";
import appConfig from "@metlife-one-opps/app/src/config";
import { paymentFailedMessage } from "../../../data";

const PolicyDetails = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(policyDetailSchema()),
  });
  const currentDate: Date = getCurrentDate();
  const minDate: Date = getSubtractedDate(currentDate, { years: 100 });
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<Date | null>(null);

  const [searchParams] = useSearchParams();
  const [paymentFailed, setPaymentFailed] = useState<boolean>(
    searchParams.get("paymentFailed") === "true"
  );
  const [serverError, setServerError] = useState("");
  const [customError, setCustomError] = useState("");
  const { setLoading } = useLoader();
  const location = useLocation();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [loginError, setLoginError] = useState<any>(null);

  const { isSessionOut, isUserInactive } = location?.state ?? {};

  document.title = "Policy Details - Metlife Nepal OPP";
  const onSubmit = async (data: { policyNumber: string; birthYear: Date }) => {
    const { policyNumber, birthYear } = data;
    setLoading(true);
    const token = await recaptchaRef.current?.executeAsync();
    if (!token) {
      setLoading(false);
      return;
    }
    try {
      const loginResponse = await login({
        policyNumber: policyNumber,
        yearOfBirth: moment(startDate).year(),
        RecaptchaToken: token,
      });

      if (loginResponse?.data?.otpStatus === otpStatus.OTP_SENT) {
        setLoading(false);
        let timeOut = setTimeout(() => {
          navigate("/verify-otp", {
            state: {
              policyNumber: policyNumber,
              birthYear: moment(birthYear).format("YYYY"),
              uuid: loginResponse?.data?.uuid,
              otpStatus: loginResponse?.data?.otpStatus,
              msisdn: loginResponse?.data?.msisdn,
            },
          });
        }, 150);
        return () => clearTimeout(timeOut);
      }
      const res = await generateToken({
        grant_type: "basic",
        uuid: loginResponse?.data?.uuid,
      });

      if (res?.status === 200) {
        setLoading(false);
        sessionStorage.setItem("isTabOpened", "true");
        localStorage.setItem(
          "@user-access",
          JSON.stringify({
            refresh_token: res.data.refresh_token,
            token: res.data.access_token,
            expiresIn: moment(new Date())
              .add(res.data.expires_in, "minutes")
              .toISOString(),
          })
        );
        navigate("/payment-type", {
          state: {
            policyNumber: policyNumber,
            birthYear: moment(birthYear).format("YYYY"),
          },
        });
      }
    } catch (error: any) {
      setLoading(false);
      recaptchaRef.current?.reset();
      const errorCode: any = error?.response?.data?.errors?.errorCode;
      const serverErrorCodes = Object.keys(serverErrorHeadings);
      switch (errorCode) {
        case BAD_REQUEST:
          setServerError(VALID_DETAILS);
          break;
        case OTP001:
          setPaymentFailed(true);
          break;
        default:
          if (serverErrorCodes.includes(errorCode)) {
            setLoginError(error?.response?.data?.errors);
          } else {
            setServerError(GENERAL_SERVER_ERROR);
          }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.localStorage.removeItem("@user-policy-data");
    const accessDataJSONString = window.localStorage.getItem("@user-access") ?? null;

    if (!!accessDataJSONString) {
      recaptchaRef.current?.reset();
      const token = recaptchaRef.current?.executeAsync();
      if (token) {
        navigate("/payment-type", {
          state: {
            LogoutRedirection: true,
          },
        });
      }
    }
  }, [location]);

  const hiddenButtonClick = async () => {
    await recaptchaRef.current?.executeAsync();
  };

  const handleErrorsOnInputDatePicker = (e: any) => {
    const value = e.target?.value;
    if (!value) return;

    if (value.length === 4) {
      const year = parseInt(value, 10);
      const date = moment([year]);
      const minDate = moment(currentDate).subtract(100, "years");
      const maxDate = moment(currentDate);
      const inRange = date.isBetween(minDate, maxDate, "year", "[]");
      setCustomError(
        inRange ? "" : `Enter the year between ${minDate.year()} - ${maxDate.year()}`
      );
    } else {
      setCustomError(
        value.length === 0 ? "" : "Please enter the year in YYYY format."
      );
    }
  };

  return (
    <>
      <PageContainer
        pageTitle="Online Premium Payment"
        pageSubTitle="Facilitating convenient and secure transactions for insurance premiums over the internet."
      >
        <div className="PolicyDetails__wrapper">
          {isSessionOut && (
            <InfoToastMessage message={SESSION_TIMEOUT_TOAST_MESSAGE} />
          )}
          {isUserInactive && (
            <InfoToastMessage message={INACTIVITY_TIMEOUT_TOAST_MESSAGE} />
          )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="PolicyDetails__login-form"
          >
            <button
              data-testid="pd-hidden-button"
              id="hiddenButton"
              style={{ display: "none" }}
              onClick={hiddenButtonClick}
            >
              Hidden Button
            </button>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={
                window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_RECAPTCHA_KEY || ""
              }
            />
            <div className="PolicyDetails__mainContainer">
              <div className="PolicyDetails__heading">
                <MetLifeGradientRow />
                <h1>Please provide your policy details</h1>
                <small>*marked fields are mandatory</small>
              </div>
              <Card customClass="PolicyDetails__form-card">
                <div className="PolicyDetails__form-container">
                  <Controller
                    name="policyNumber"
                    control={control}
                    render={({ field }) => (
                      <>
                        <MetLifeInput
                          data-testid="MetLifeInput__text__policyNumber"
                          maxLength={10}
                          control={control}
                          inputHasValue={!!field.value}
                          name={"policyNumber"}
                          onInput={(e) => {
                            const value = e.currentTarget.value;
                            const cursorPosition = e.currentTarget.selectionStart;
                            e.currentTarget.value = value.toUpperCase();
                            setServerError("");
                            e.currentTarget.setSelectionRange(
                              cursorPosition,
                              cursorPosition
                            );
                          }}
                          placeholder="Policy Number*"
                          type="text"
                          isError={!!errors?.policyNumber?.message || !!serverError}
                          errMessage={errors?.policyNumber?.message || serverError}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="birthYear"
                    control={control}
                    defaultValue={startDate ? startDate : undefined}
                    render={({ field }) => (
                      <InputDatePicker
                        data-testid="InputDatePicker__input"
                        name={"birthYear"}
                        control={control}
                        maxDate={currentDate}
                        minDate={minDate}
                        selected={startDate}
                        onChange={(date) => {
                          if (date) {
                            setServerError("");
                            setCustomError("");
                            setValue("birthYear", date);
                            setStartDate(date);
                          }
                        }}
                        onKeyDown={handleErrorsOnInputDatePicker}
                        onChangeRaw={handleErrorsOnInputDatePicker}
                        autoComplete="off"
                        showYearPicker={true}
                        dateFormat="yyyy"
                        yearItemNumber={9}
                        serverErr={serverError || customError}
                        isError={
                          !!errors?.birthYear?.message ||
                          !!serverError ||
                          !!customError
                        }
                        errMessage={
                          errors?.birthYear?.message || serverError || customError
                        }
                        placeholderText={"Year of Birth of Policy Owner *"}
                      />
                    )}
                  />
                </div>
              </Card>
              <div className="PolicyDetails__submit-button-container">
                <MetLifeButton
                  data-testid="PolicyDetails__submit-button"
                  type="submit"
                  className="PolicyDetails__submit-button"
                  // variant="secondary"
                >
                  Submit
                </MetLifeButton>
              </div>
            </div>
          </form>
          <AlertPopup
            data-testid="PolicyDetails__alert-popup-payment-failed"
            alertWrapperCustomClass="PolicyDetails__alert-popup-1"
            isVisible={paymentFailed}
            description={
              <span>
                {paymentFailedMessage.line1}
                <strong>{paymentFailedMessage.contactNumber}</strong>
                {paymentFailedMessage.line2}
              </span>
            }
            heading="Transaction Failed"
            primaryBtnText="Close"
            onPressPrimary={() => {
              setPaymentFailed(false);
              window.location.href = window.location.origin;
            }}
          />
          <AlertPopup
            data-testid="VerifyOTP__alert-popup-payment-failed"
            alertWrapperCustomClass="PolicyDetails__alert-popup-1"
            isVisible={!!loginError}
            description={loginError?.desc}
            heading={serverErrorHeadings[loginError?.errorCode] || ""}
            primaryBtnText="Close"
            onPressPrimary={() => {
              setLoginError(null);
            }}
          />
        </div>
      </PageContainer>
    </>
  );
};

const InfoToastMessage = ({ message }: any) => {
  const [timer, setTimer] = useState(appConfig.toastMessageDurationInSeconds);

  useEffect(() => {
    let timerInterval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(timerInterval);
          if (window.document.getElementById("PolicyDetails__session-timeout")) {
            window.document
              .getElementById("PolicyDetails__session-timeout")
              ?.remove();
          }
          return 0;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  return (
    <div className="toast-message info" id="PolicyDetails__session-timeout">
      <div className="content">
        <div className="pre-icon">
          <InfoCircleIcon />
        </div>
        <div className="message">{message}</div>
        <div
          data-testid="PolicyDetails__info-toast-close-icon"
          className="close-icon"
          role="button"
          onClick={(e) => {
            if (document.getElementById("PolicyDetails__session-timeout")) {
              document.getElementById("PolicyDetails__session-timeout")?.remove();
            }
          }}
        >
          <CloseIcon height={14} width={14} />
        </div>
      </div>
    </div>
  );
};

export default PolicyDetails;
