import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "./Modal.css";

interface ModalPropsType {
  children: React.ReactNode;
  isVisible: boolean;
  onClose?: () => void;
  title?: string;
  showCloseBtn?: boolean;
  showModalHeader?: boolean;
  modalHeaderStyle?: React.CSSProperties;
  modalContainerStyles?: React.CSSProperties;
  modalContentStyles?: React.CSSProperties;
  modalWrapperCustomClass?: string;
}

const Modal: React.FC<ModalPropsType> = ({
  children,
  isVisible,
  onClose = () => {},
  title,
  showCloseBtn = true,
  modalHeaderStyle = {},
  modalContainerStyles = {},
  modalContentStyles = {},
  showModalHeader = true,
  modalWrapperCustomClass = "",
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isVisible === true) {
      setShowModal(true);
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "auto";
      setShowModal(false);
      onClose();
    }
  }, [isVisible]);

  return (
    <>
      {isVisible &&
        createPortal(
          <>
            <div className="Modal__container" style={modalContainerStyles}>
              <div
                className={`Modal__wrapper ${modalWrapperCustomClass} ${
                  showModal
                    ? "animation__slide_fade_in"
                    : "animation__slide_fade_out"
                }`}
              >
                {showModalHeader && (
                  <div className="Modal__header" style={modalHeaderStyle}>
                    {title && <div className="Modal__header__title">{title}</div>}
                    {showCloseBtn && (
                      <div onClick={onClose} className="closeIcon">
                        &times;
                      </div>
                    )}
                  </div>
                )}
                <div className="Modal__content" style={modalContentStyles}>
                  {" "}
                  {children}
                </div>
              </div>
            </div>
          </>,
          document.body
        )}
    </>
  );
};

export default Modal;
