import React, { useState } from "react";
import classes from "./MTMobileMenu.module.css";
import megaMenuLinks from "../../data/mega-menu.json";
import { ChevronRight } from "../../assets";

type MTMobileMenuProps = {
  children?: React.ReactElement;
};

export const MTMobileMenu: React.FC<MTMobileMenuProps> = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleActive = (idx: number | null) => {
    if (activeIndex !== idx) {
      setActiveIndex(idx);
    } else {
      setActiveIndex(null);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <section className={classes.row1}>
          {megaMenuLinks.map((mm: any) => (
            <div className={classes["mega-menu"]} key={`mobile-megamenu-${mm?.id}`}>
              <button
                data-testid={`mobile-megamenu-${mm?.id}`}
                data-toggle-status={activeIndex === mm.id}
                className={classes["mega-menu-heading"]}
                onClick={() => {
                  toggleActive(mm.id);
                }}
              >
                <h3>
                  <a href={mm?.link ?? "#"} target="_blank">{mm.title}</a>
                </h3>
                {activeIndex === mm.id ? (
                  <ChevronRight direction="down" />
                ) : (
                  <ChevronRight direction="right" />
                )}
              </button>
              <div
                className={`${classes["mega-menu-submenu"]} ${
                  activeIndex === mm.id ? classes["submenu-active"] : ""
                }`}
              >
                <ul>
                  {mm.subMenu.map((submenu: any) => (
                    <li key={`mobile-submenu-${submenu?.id}`}>
                      <a href={submenu?.link ?? "#"} target="_blank">{submenu.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};
