import * as Yup from "yup";
import { MANDATORY, VALID_DETAILS } from "../../../constants";

export const policyDetailSchema = () => {
  return Yup.object({
    policyNumber: Yup.string()
      .required(MANDATORY)
      .matches(/^(?=.*\d)[a-zA-Z0-9]+$/, VALID_DETAILS)
      .min(1, VALID_DETAILS)
      .max(10, VALID_DETAILS),

    birthYear: Yup.date().required(MANDATORY),
  });
};
