export const paymentTypeOptions: any = [
  { id: 1, value: "premium", label: "Premium", key: "PREMIUM" },
  { id: 2, value: "apl", label: "APL", key: "REPAYMENT_OF_APL" },
  { id: 3, value: "lapse", label: "Lapse", key: "LAPSE_PAYMENT" },
  { id: 4, value: "loan", label: "Loan", key: "REPAYMENT_OF_LOAN" },
];

export const paymentTypeOptionsLabels: any = {
  PREMIUM: "Premium",
  REPAYMENT_OF_LOAN: "Repayment of Loan",
  REPAYMENT_OF_APL: "Repayment of APL",
  LAPSE_PAYMENT: "Lapse Payment",
};
