export const MANDATORY = "This field is required.";
export const MOBILE_MIN_LENGTH = "Mobile Number must have 10 characters.";
export const VALID_MOBILE_NUMBER = "Please enter a valid mobile number.";
export const VALID_EMAIL = "Please enter a valid id.";
export const VALID_DETAILS = "Please enter valid details.";
export const NUMBERS_ONLY = "Please enter numbers only.";
export const DECIMAL_PLACES = "Please enter a number with two decimal places.";
export const VALUES_BETWEEN_ERROR = (min: any, max: any) =>
  `Please enter a value between ${min} and ${max}`;
export const OTP_ERRORS = {
  invalidOTPAttempts:
    "You have entered an incorrect OTP several times. Kindly attempt to log in again after 30 minutes or contact customer care.",
  mandatory: "This field is required.",
  invalidOTP: "Please enter a valid OTP.",
  resendServerError:
    "OTP could not be sent.Please try again later or contact Customer Support at 16344 for assistance.",
  resendMaxLimitReached: "OTP limit reached. Please contact customer care",
};
