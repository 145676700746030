import { PageContainer } from "@metlife-one-opps/page-container";
import React, { useEffect, useState } from "react";
import "./VerifyOTP.css";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import { Card } from "@metlife-one-opps/card";
import { MetLifeGradientRow } from "@metlife-one-opps/metlife-gradient-row";
import { MetlifeOTPInput } from "@metlife-one-opps/metlife-otp-input";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import {
  GENERAL_SERVER_ERROR,
  OTP_ERRORS,
  otpStatus as otpStatusCodes,
} from "../../../constants";
import config from "@metlife-one-opps/app/src/config";
import { useLocation, useNavigate } from "react-router-dom";
import { sendOTP, verifyOtpAndGenerateToken } from "@metlife-one-opps/services";
import moment from "moment";
import { useLoader } from "@metlife-one-opps/hooks";
import { AlertPopup } from "@metlife-one-opps/alert-popup";

export const otpValidateSchema = Yup.object({
  otp: Yup.string().required(OTP_ERRORS.mandatory).length(6, OTP_ERRORS.invalidOTP),
});

const VerifyOTP = () => {
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(otpValidateSchema),
    defaultValues: {
      otp: "",
    },
  });
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const { state } = useLocation();
  const uuid = state?.uuid;
  const phoneNumber = state?.msisdn;
  const otpStatus = state?.otpStatus;
  const policyNumber = state?.policyNumber;
  const birthYear = state?.birthYear;
  const [triggerResendTimer, setTriggerResendTimer] = useState(true);
  const [isOTPAttemptsExceeded, setIsOTPAttemptsExceeded] = useState(false);
  const [timer, setTimer] = useState(config?.otpResendTimeInSeconds);
  const [timerText, setTimerText] = useState("");

  const getTimeoutText = (t: number) => {
    if (t === config.otpResendTimeInSeconds) return "02:00 min";
    const minutes = Math.floor(t / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (t % 60).toString().padStart(2, "0");
    const suffix = t >= 60 ? " min" : " seconds";

    return `${minutes}:${seconds}${suffix}`;
  };

  const startResendOTPCountdown = () => {
    const intval = setInterval(() => {
      setTimer((prevTimerValue) => {
        if (prevTimerValue <= 1) {
          clearInterval(intval);
          setTriggerResendTimer(false);
          return 0;
        } else {
          return prevTimerValue - 1;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    if (timer > 0) {
      setTimerText(getTimeoutText(timer));
    }
  }, [timer]);

  useEffect(() => {
    if (triggerResendTimer) {
      startResendOTPCountdown();
    }
  }, [triggerResendTimer]);

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const res = await verifyOtpAndGenerateToken({
        grant_type: "basic",
        uuid: uuid,
        otp_code: data?.otp.toString(),
      });
      if (res?.status === 200) {
        //SUCCESS CODE GOES HERE
        localStorage.setItem(
          "@user-access",
          JSON.stringify({
            refresh_token: res.data.refresh_token,
            token: res.data.access_token,
            expiresIn: moment(new Date())
              .add(res.data.expires_in, "minutes")
              .toISOString(),
          })
        );
        navigate("/payment-type", {
          state: {
            policyNumber: policyNumber,
            birthYear: birthYear,
          },
        });
      }
      setLoading(false);
    } catch (error: any) {
      const errorStatusCode: any = error?.response?.status;
      const isOtpLimitExceeded: any = error?.response?.data?.error === "UBC009";
      if (isOtpLimitExceeded) {
        setIsOTPAttemptsExceeded(true);
      } else if (errorStatusCode === 400) {
        setError("otp", {
          type: "server",
          message: OTP_ERRORS.invalidOTP,
        });
      }
      setLoading(false);
      console.log("Verify OTP", error);
    }
  };

  const resendOTP = async () => {
    try {
      setLoading(true);
      const otpSentResponse = await sendOTP({
        uuid: uuid,
      });

      if (
        otpSentResponse?.data?.data?.otpStatus === otpStatusCodes.OTP_SENT ||
        otpSentResponse?.data?.data?.otpStatus === otpStatusCodes.OTP_RESENT
      ) {
        setTimer(config?.otpResendTimeInSeconds);
        setTriggerResendTimer(true);
      }
      setLoading(false);
    } catch (error: any) {
      const errorCode: any = error?.response?.data?.errors?.errorCode;
      if (errorCode === "OTP001") {
        setError("otp", {
          type: "server",
          message: OTP_ERRORS.resendMaxLimitReached,
        });
      } else if (errorCode === "OTP005") {
        setError("otp", {
          type: "server",
          message: OTP_ERRORS.resendServerError,
        });
      } else {
        setError("otp", {
          type: "server",
          message: GENERAL_SERVER_ERROR,
        });
      }
      setLoading(false);
      console.log("error while sending otp", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (phoneNumber === undefined && otpStatus !== otpStatusCodes.OTP_SENT) {
      navigate("/");
    }
    window.history.replaceState({}, "");
  }, []);

  return (
    <PageContainer
      pageTitle="Online Premium Payment"
      pageSubTitle="Facilitating convenient and secure transactions for insurance premiums over the internet."
    >
      <div className="VerifyOTP__wrapper">
        <form onSubmit={handleSubmit(onSubmit)} data-testid="VerifyOTP__otpform">
          <div className="VerifyOTP__mainContainer">
            <div className="VerifyOTP__heading">
              <MetLifeGradientRow />
              <h1>Verify Your Account</h1>
            </div>
            <Card customClass="VerifyOTP__form-card">
              <div className="VerifyOTP__form-container">
                <div className="VerifyOTP__otp-input-header">
                  <h4>Enter the 6-digit One-Time Password</h4>
                  <p>
                    A 6-digit one-time password (OTP) has been sent to your
                    registered mobile number ending in {phoneNumber}.
                  </p>
                </div>
                {/* create a 6 digit otp input with inline stylings */}
                <div className="VerifyOTP__otp-input">
                  <MetlifeOTPInput
                    data-testid="otp-input"
                    onChange={(otp) => {
                      if (otp) {
                        setValue("otp", otp);
                        trigger("otp", { shouldFocus: true });
                      }
                    }}
                    isError={!!errors?.otp?.message}
                    errMessage={errors?.otp?.message}
                  />
                  <Controller
                    name="otp"
                    control={control}
                    render={() => (
                      <input
                        data-testid="otp-hidden-input"
                        type="hidden"
                        {...control.register("otp")}
                      />
                    )}
                  />
                </div>
                <div>
                  <p
                    className={`VerifyOTP__otp-resend-section ${
                      timer > 0 ? "timer-active" : ""
                    }`}
                  >
                    <button
                      type="button"
                      data-testid="verifyotp-resend-btn"
                      onClick={resendOTP}
                    >
                      Resend OTP
                    </button>
                    <div>in {timerText}</div>
                  </p>
                  <div className="VerifyOTP__otp-resend-note">
                    If you did not receive your verification code after several
                    requests, please contact
                    <a href="tel:+977-159-701-66"> +977 15970166</a>
                  </div>
                </div>
              </div>
            </Card>
            <AlertPopup
              data-testid="VerifyOTP__alert-popup-otp-attempts-exceeded"
              isVisible={isOTPAttemptsExceeded}
              description={OTP_ERRORS.invalidOTPAttempts}
              heading={"OTP Attempts Exceeded."}
              primaryBtnText="Close"
              onPressPrimary={() => {
                setIsOTPAttemptsExceeded(false);
              }}
            />
            <div className="VerifyOTP__submit-button-container">
              <MetLifeButton
                data-testid="VerifyOTP__back-button"
                type="button"
                className="VerifyOTP__submit-button"
                variant="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </MetLifeButton>
              <MetLifeButton
                data-testid="VerifyOTP__submit-button"
                type="submit"
                className="VerifyOTP__submit-button"
                // variant="secondary"
              >
                Submit
              </MetLifeButton>
            </div>
          </div>
        </form>
      </div>
    </PageContainer>
  );
};

export default VerifyOTP;
