import React from "react";
import countriesRegions from "../../data/region_countries.json";
import classes from "./MetlifeCountrySelect.module.css";
type MetlifeCountrySelectProps = {
  selectedCountry?: string;
  onSelect?: (country: string) => void;
};

export const MetlifeCountrySelect: React.FC<MetlifeCountrySelectProps> = ({
  selectedCountry = "Nepal",
  onSelect = () => {},
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.innerWrapper}>
        <button className={classes.selector} data-testid="metlife-country-select">
          <div className={classes.country}>
            <div
              className={`${classes.countryFlag} ${
                classes["country-flag-" + selectedCountry.replace(/ /g, "-")]
              }`}
            ></div>
            <div className={classes.countryName1}>{selectedCountry}</div>
          </div>
          <div className={classes.caretIcon}>
            <img
              src="https://www.metlife.com.np/static/images/icons_subnav/icon_selectionarrows_white.png"
              alt=""
            />
          </div>
        </button>
        <div className={classes.list} data-testid="countries-list">
          {Object.keys(countriesRegions).map((region, index) => {
            return (
              <div key={region} className={classes.countryRegion}>
                <div className={classes.region}>{region}</div>
                {countriesRegions[region].map((country: any, index: number) => {
                  return (
                    <div
                      data-testid={`metlife-select-country-${country.country}`}
                      key={region + country.id}
                      onClick={() => {
                        onSelect(country.country);
                        window.open(
                          country.url || "https://www.metlife.com.np/",
                          "_blank"
                        );
                      }}
                      className={`${classes.country} ${classes.countryInList}`}
                    >
                      <div
                        className={`${classes.countryFlag} ${
                          classes[
                            "country-flag-" + country.country.replace(/ /g, "-")
                          ]
                        }`}
                      ></div>
                      <div className={classes.countryName}>{country.country}</div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
