import * as yup from "yup";
export const contactUsSchema = yup.object().shape({
  email: yup
    .string()
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email format is incorrect")
    .email("Email format is incorrect")
    .notOneOf(["@example.com"], "Email @example.com is not allowed")
    .max(50, " "),
  firstName: yup
    .string()
    .required("Your First Name is required.")
    .matches(/^[A-Za-z]+$/, "Only Alphabets")
    .max(30, " "),
  lastName: yup
    .string()
    .required("Your Last Name is required.")
    .matches(/^[A-Za-z]+$/, "Only Alphabets")
    .max(30, " "),
  telephoneNumber: yup
    .string()
    .matches(/^\d*$/, " ")
    .required("Please provide your telephone number.")
    .max(20, " "),
  address: yup
    .string()
    .required("Please provide your correspondence address.")
    .max(100, " "),
  products: yup.string(),
  textMessage: yup.string().max(300, " "),
});
