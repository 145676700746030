import { useLoader } from "@metlife-one-opps/hooks";
import { finishPayment } from "@metlife-one-opps/services";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const PaymentStatusCheck = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const [searchParams] = useSearchParams();
  const amount: any = searchParams.get("amount");
  const fonePayPaymentStatus: any =
    searchParams.get("PS") || searchParams.get("status");
  const policyData = JSON.parse(
    window.localStorage.getItem("@user-policy-data") || "{}"
  );
  const redirectToFailed = () => {
    localStorage.clear();
    window.location.replace(
      `${window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL}?transactionUuid=${policyData?.transactionId}&amount=${amount}&paymentFailed=true`
    );
  };
  const finalizePaymentService = async () => {
    if (
      fonePayPaymentStatus === "false" ||
      fonePayPaymentStatus === "User canceled"
    ) {
      redirectToFailed();
      return false;
    }
    try {
      setLoading(true);
      const res = await finishPayment({ transactionId: policyData?.transactionId });
      setLoading(false);
      if (res?.status === 200) {
        const paymentStatus = res?.data?.data?.paymentStatus;
        if (paymentStatus === "FAILED" || paymentStatus === "UNKNOWN") {
          redirectToFailed();
        }

        navigate("/payment-done", {
          state: {
            policyDetails: res?.data?.data ?? {},
          },
        });
      }
    } catch (error) {
      console.log("Error while finishing the payment", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    let timeout = setTimeout(() => {
      finalizePaymentService();
    }, 200);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="payment-status-container">
      <div className="payment-status-message">redirecting please wait...</div>
    </div>
  );
};
