import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "@metlife-one-opps/hooks";
import {
  ChevronRight,
  CloseIcon,
  HomeIcon,
  MenuIcon,
  MetLifeLogo,
  SearchIcon,
} from "../assets";
import { AppFooter, MTDesktopMenu, MTMobileMenu } from "../components";
import { ContactUsSlider } from "../components/ContactUsSlider/ContactUsSlider";
import "./PageContainer.css";
type PageContainerProps = {
  children?: React.ReactElement;
  pageTitle?: string;
  showPageTitle?: boolean;
  pageSubTitle?: string;
  onLogout?: () => void;
};

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  pageTitle,
  showPageTitle = true,
  pageSubTitle,
  onLogout,
}) => {
  const { onUserLogout } = useAuth();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuShown, setIsMenuShown] = useState(false);
  const [showSearchDialogBox, setShowSearchDialogBox] = useState(false);
  const searchDialogBoxRef = useRef<HTMLDivElement | null>(null);
  const [query, setQuery] = useState("");
  const onUserScroll = () => {
    setIsScrolled(false);
    if (window.scrollY > 10) {
      setIsScrolled(true);
    }
  };
  const [isContactSliderOpen, setContactSliderOpen] = useState(false);
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        searchDialogBoxRef.current &&
        !searchDialogBoxRef.current.contains(event.target as Node) &&
        (event.target as HTMLElement).nodeName !== "INPUT" &&
        (event.target as HTMLElement).nodeName !== "BUTTON" &&
        (event.target as HTMLElement).nodeName !== "SVG"
      ) {
        setShowSearchDialogBox(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", onUserScroll);
    return () => window.removeEventListener("scroll", onUserScroll);
  }, []);

  const onSearch = (query: any) => {
    const searchURL = `https://www.metlife.com.np/en/searchresultspage/?query=${encodeURIComponent(
      query
    )}`;

    window.open(searchURL, "_blank");
    setShowSearchDialogBox(false);
  };
  return (
    <main
      className="App__main"
      data-testid="main-page-container"
      data-is-scrolled={isScrolled}
    >
      <div>
        <header
          className={`AppHeader__container ${
            isScrolled ? "AppHeader__container__scrolled" : ""
          }`}
        >
          <div className="App__fluid-container">
            <div className="AppHeader__wrapper">
              <div className="AppHeader_leftLinks">
                <a
                  className="top-menu menu"
                  role="button"
                  data-testid="top-menu-toggle"
                  onClick={() => setIsMenuShown(!isMenuShown)}
                >
                  {isMenuShown ? <CloseIcon /> : <MenuIcon />}
                  {isMenuShown ? <span>Close</span> : <span>Menu</span>}
                </a>
                {/* Menus */}
                {isMenuShown && <MTDesktopMenu />}
                {isMenuShown && <MTMobileMenu />}
                {/* Menus */}
                <a
                  role="button"
                  data-testid="home-link"
                  className="top-menu home"
                  onClick={() => {
                    // navigate it to homepage
                    window.location.href = "/";
                  }}
                >
                  <HomeIcon />
                  <span>Home</span>
                </a>
                {!showSearchDialogBox ? (
                  <a
                    role="button"
                    data-testid="show-search-dialog-box"
                    className="top-menu search"
                    onClick={() => {
                      setShowSearchDialogBox(true);
                    }}
                  >
                    <SearchIcon />
                    <span data-testid="search-menuBar">Search</span>
                  </a>
                ) : (
                  <div
                    data-testid="search-dialog-box"
                    className={`search-dialog-box ${
                      showSearchDialogBox
                        ? "animation__slide_fade_in"
                        : "animation__slide_fade_out"
                    }`}
                    ref={searchDialogBoxRef}
                  >
                    <input
                      data-testid="search-box-input"
                      type="text"
                      value={query}
                      onChange={(event) => setQuery(event.target.value)}
                    />
                    <div className="search-icon">
                      <button
                        data-testid="internal-search-button"
                        role="button"
                        onClick={() => onSearch(query)}
                        disabled={query === "" ? true : false}
                      >
                        <SearchIcon style={{ pointerEvents: "none" }} />
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="AppHeader__logo">
                <MetLifeLogo />
              </div>
              <div className="AppHeader_rightLinks">
                <a
                  className="top-menu contact-us"
                  onClick={(e) => {
                    e.preventDefault();
                    setContactSliderOpen(true);
                  }}
                >
                  <svg id="icon-contact-us" viewBox="0 0 16 16">
                    <path d="M13.016 5.487a5.79 5.79 0 0 0-1.707-3.781A5.838 5.838 0 0 0 7.165 0 5.838 5.838 0 0 0 3.02 1.706a5.785 5.785 0 0 0-1.707 3.78A3.253 3.253 0 0 0 0 8.1a3.26 3.26 0 0 0 3.257 3.257c.36 0 .65-.292.65-.652v-5.21a.651.651 0 0 0-.65-.652c-.187 0-.371.017-.55.048a4.562 4.562 0 0 1 4.458-3.587 4.56 4.56 0 0 1 4.457 3.587 3.203 3.203 0 0 0-.55-.048.652.652 0 0 0-.651.652v5.21c0 .36.292.652.652.652.223 0 .44-.023.65-.066v1.54a.592.592 0 0 1-.591.592h-1.123a.652.652 0 0 0-.613-.434H7.165a.65.65 0 0 0-.651.652v.868c0 .359.29.65.65.65h2.232a.65.65 0 0 0 .613-.433h1.123c1.045 0 1.895-.85 1.895-1.894V10.7a3.253 3.253 0 0 0-.011-5.215" />
                  </svg>
                  <span>Contact MetLife</span>
                </a>

                {(window.localStorage.getItem("@user-access") ||
                  window.localStorage.getItem("@user-policy-data")) && (
                  <a
                    data-testid="logout-link"
                    className="top-menu contact-us"
                    onClick={(e) => {
                      e.preventDefault();

                      if (onLogout) {
                        onLogout();
                      } else {
                        onUserLogout();
                      }
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.83337 8.95963L14.375 8.95964L11.6667 6.2513L13.125 4.79297L18.3334 10.0013L13.125 15.2096L11.6667 13.7513L14.375 11.043L5.83337 11.043L5.83337 8.95963ZM1.66671 1.66797L6.87504 1.66797L6.87504 3.7513L3.75004 3.7513L3.75004 16.2513L6.87504 16.2513L6.87504 18.3346L1.66671 18.3346L1.66671 1.66797Z"
                        fill="#333333"
                      />
                    </svg>
                    <span>LOGOUT</span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </header>
        {showSearchDialogBox && (
          <div
            className={`search-dialog-box_mobile ${
              showSearchDialogBox
                ? "animation__slide_fade_in"
                : "animation__slide_fade_out"
            }`}
            ref={searchDialogBoxRef}
          >
            <form>
              <input
                data-testid="search-box-input-mobile"
                type="text"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              />
              <div className="search-icon-mobileView">
                <button
                  role="button"
                  data-testid="internal-search-button-mobile"
                  onClick={() => onSearch(query)}
                  disabled={query === "" ? true : false}
                >
                  <SearchIcon />
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="AppContainer__main">
          <div className="AppContainer__innerWrapper">
            <div className="AppContainer__page-title-container">
              <div className="App__fluid-container">
                <div className="breadcrum">
                  <span> Home </span>
                  <span style={{ position: "relative", top: "1px" }}>
                    <ChevronRight height={12} width={12} />{" "}
                  </span>
                  <span>Online Premium Payment</span>
                </div>
                {showPageTitle && <div className="App__pageTitle">{pageTitle}</div>}
                {pageSubTitle && (
                  <div className="App__pageSubTitle">{pageSubTitle}</div>
                )}
              </div>
            </div>

            <div className="App__pageContent">{children}</div>
          </div>
        </div>
      </div>
      <ContactUsSlider
        isShown={isContactSliderOpen}
        onClose={() => setContactSliderOpen(false)}
      />

      <AppFooter />
    </main>
  );
};

export default PageContainer;
