const checkCircle = require("./check-circle.png");
const crossCircle = require("./cross-circle.png");

const eSewaImage = require("./e-sewa.png");
const fonePayImage = require("./fone-pay.png");
const imePayImage = require("./imepay.png");
const khaltiImage = require("./khalti.png");
const connectIps = require("./connect-ips.png");
const checkImage = require("./check.png");

import { PlusIcon, MinusIcon, InfoCircleIcon, CheckCircle } from "./icons";

export {
  checkCircle,
  crossCircle,
  PlusIcon,
  MinusIcon,
  InfoCircleIcon,
  CheckCircle,
  eSewaImage,
  fonePayImage,
  imePayImage,
  khaltiImage,
  connectIps,
  checkImage,
};
