import { useLoader } from "@metlife-one-opps/hooks";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PencilIcon } from "../../assets/icons";
import { AlertPopup } from "@metlife-one-opps/alert-popup";
import { Card } from "@metlife-one-opps/card";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import { MetLifeGradientRow } from "@metlife-one-opps/metlife-gradient-row";
import { MetLifeInput, MetLifeRadioInput } from "@metlife-one-opps/metlife-input";
import { MetLifeDropDown } from "./MetLifeDropDown/MetLifeDropDown";
import { REQUEST_SUBMITTED_DESC, REQUEST_SUBMITTED_HEADING } from "../../constants";
import { getOptionList, getPayableAmount, paymentGatewaysList } from "../../data";
import { PaymentVendorTypes, paymentGatewayRule } from "../pages";
import { UpdateMobileNumberModal } from "../components";
import moment from "moment";
import { updateMobileNumber, startPayment } from "@metlife-one-opps/services";
import { formatAmount } from "@metlife-one-opps/utils";

export const SelectPaymentGateways = ({ state }: any) => {
  const navigate = useNavigate();
  const { policy, selectedPayment, paymentId } = state ?? {};

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const { setLoading } = useLoader();
  const optionList = getOptionList(policy, selectedPayment);
  const [dropDowwnValue, setDropDownValue] = useState(optionList[0]);
  const [showRequestSentAlert, setShowRequestSentAlert] = useState(false);
  const [showUpdateMobileNumberModal, setShowUpdateMobileNumberModal] =
    useState(false);
  const [paymentVendor, setPaymentVendor] = useState<PaymentVendorTypes>("");
  const [newMobile, setNewMobile] = useState(policy?.policyOwnerMobile);

  const onSelectPaymentGateway = async (item: any) => {
    setPaymentVendor(item.altText);
    setValue("paymentVendor", item.altText);
    trigger("paymentVendor", { shouldFocus: true });
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      let otherAmount = data?.otherAmount;

      if (dropDowwnValue.value) {
        otherAmount = null;
        reset();
      }

      const payableAmount = getPayableAmount(policy, otherAmount);

      let requestPayload: any = {
        transactionId: paymentId,
        paymentVendor: paymentVendor,
        paymentType: selectedPayment.value,
      };

      if (otherAmount) {
        requestPayload.customAmount = "NPR " + formatAmount(payableAmount);
      } else {
        requestPayload.payableAmount = "NPR " + formatAmount(payableAmount);
      }

      const res = await startPayment(requestPayload);
      setLoading(false);
      if (res?.status === 200) {
        const paymentVendorItem = paymentGatewaysList.find(
          (pvi) => pvi.altText === paymentVendor
        );
        const userAccessJSON = localStorage.getItem("@user-access") || "";
        const userAccessData = userAccessJSON ? JSON.parse(userAccessJSON) : {};
        localStorage.setItem(
          "@user-policy-data",
          JSON.stringify({
            vendorName: paymentVendorItem?.value,
            transactionId: paymentId,
            accessToken: userAccessData?.token,
            refresh_token: userAccessData?.refresh_token,
          })
        );

        navigate("/payment-overview", {
          state: {
            item: paymentVendor,
            policy: policy,
            otherAmount: otherAmount,
            selectedPayment: { ...selectedPayment, amount: payableAmount },
            paymentId: paymentId,
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("Error while starting the payment.", error);
    }
  };
  const handleSelect = (selected: any) => {
    setDropDownValue(selected);
  };

  const onEditMobileNumber = () => {
    setShowUpdateMobileNumberModal(true);
  };
  const handleChangeMobileNumber = async (newMobile: any) => {
    const payload = {
      transactionId: paymentId,
      newMobileNumber: newMobile,
    };
    try {
      setLoading(true);
      const res = await updateMobileNumber(payload);
      if (res?.status === 200) {
        setNewMobile(newMobile);
        setShowUpdateMobileNumberModal(false);
        setShowRequestSentAlert(true);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log("Error while updating mobile number.", e);
    }
  };

  useEffect(() => {
    if (!dropDowwnValue?.value) {
      setValue("otherAmount", "");
    }
  }, [dropDowwnValue]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="SelectPG__form">
        <div className="ConfirmPayment_heading">
          <MetLifeGradientRow />
          <h1>Select Payment Amount and Payment Gateway</h1>
        </div>
        <div className="ConfirmPayment_cards">
          <div className="cardComponent">
            <Card>
              <div className="ConfirmPayment_cardContainer">
                <div className="cardHeading">
                  <h3>Policy Details</h3>
                </div>
                <br />
                <div className="StandingInstructions__mandate-information">
                  <div className="row">
                    <div className="column-1">
                      <div className="label">Policy Owner’s Name</div>
                      <div className="value">{policy?.policyOwnerName}</div>
                    </div>
                    <div className="column-2">
                      <div className="label">Policy Owner’s Email</div>
                      <div className="value">{policy?.policyOwnerEmail}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column-1">
                      <div className="label">Mobile Number</div>
                      <div className="value">
                        <div className="update-policyOwnerMobileNumber">
                          <span>+977 {newMobile}</span>
                          <button
                            type="button"
                            data-testid="pencil-icon-edit"
                            onClick={onEditMobileNumber}
                          >
                            <PencilIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="column-2">
                      <div className="label">Payment Type</div>
                      <div className="value">{selectedPayment?.label}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column-1">
                      <div className="label">Policy Number</div>
                      <div className="value">{policy?.policyNumber}</div>
                    </div>
                    <div className="column-2">
                      <div className="label">Payment Due Date</div>
                      <div className="value">
                        {moment(policy?.paymentDueDate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column-1">
                      <div className="label">Policy Status</div>
                      <div className="value">{policy?.policyStatus}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="cardComponent">
            <Card>
              <div className="ConfirmPayment_cardContainer">
                <div className="cardHeading">
                  <h3>Select Payable Amount</h3>
                </div>
                <div className="SelectAmountContainer">
                  <MetLifeDropDown
                    optionsList={optionList}
                    identifier="value"
                    renderItemComponent={(item: any) => (
                      <div
                        key={item.id}
                        role="button"
                        data-testid={`dropdown-item-${item.id}`}
                        className={`ConfirmPayment_InputDropDown__item ${
                          dropDowwnValue?.label === item.label ? "active" : ""
                        } `}
                        onClick={() => handleSelect(item)}
                      >
                        <div>{item.label} </div>
                        <div>{item.value}</div>
                      </div>
                    )}
                    renderSelectedComponent={() => (
                      <div className="ConfirmPayment_InputDropDown__Selected">
                        <div className="label">{dropDowwnValue?.label} </div>
                        <div className="value">
                          {dropDowwnValue?.value && (
                            <strong>{dropDowwnValue?.value}</strong>
                          )}
                        </div>
                      </div>
                    )}
                    containerCustomClass="ConfirmPayment__dropdown-custom"
                  />
                  {!dropDowwnValue?.value && (
                    <Controller
                      name="otherAmount"
                      control={control}
                      rules={paymentGatewayRule(policy?.payableAmount.split(" ")[1])}
                      render={({ field }) => (
                        <MetLifeInput
                          containerCustomClass={`ConfirmPayment__otheramount-custom ${
                            !!errors?.otherAmount?.message ? "has-error" : ""
                          }`}
                          control={control}
                          inputHasValue={!!field.value}
                          name={"otherAmount"}
                          data-testid="selectPG__otherAmount"
                          placeholder="Enter Your Amount"
                          isError={!!errors?.otherAmount?.message}
                          errMessage={errors?.otherAmount?.message}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            </Card>
          </div>
          <div className="cardComponent">
            <Card>
              <div className="ConfirmPayment_cardContainer">
                <div className="cardHeading">
                  <h3>Select Payment Gateway</h3>
                  <div className={"ConfirmPayment__payment-gateways"}>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Please select a payment gateway",
                        },
                      }}
                      name="paymentVendor"
                      control={control}
                      render={({ field }) => (
                        <input type="hidden" {...field} value={paymentVendor} />
                      )}
                    />
                    {paymentGatewaysList.map((pg) => (
                      <div
                        className={`cf-payment__gateway ${
                          paymentVendor === pg.altText ? "active" : ""
                        } ${
                          pg.isDisabled
                            ? "ConfirmPayment__payment-gateways-disabled"
                            : ""
                        }`}
                        data-testid={`gateway-${pg.altText}-id`}
                        key={pg.id}
                        onClick={() =>
                          pg.isDisabled ? () => {} : onSelectPaymentGateway(pg)
                        }
                      >
                        <MetLifeRadioInput
                          label={pg?.label}
                          value={pg?.altText}
                          checked={paymentVendor === pg.altText}
                        >
                          <img src={pg.url} alt={pg.altText} />
                        </MetLifeRadioInput>
                      </div>
                    ))}
                  </div>
                  {errors?.paymentVendor?.message && (
                    <div className="is-error">Please select a payment gateway.</div>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="ConfirmPayment_button">
          <MetLifeButton
            type="button"
            variant="secondary"
            onClick={() => {
              navigate(-1);
            }}
            data-testid="SelectPaymentGateways_back"
          >
            Back
          </MetLifeButton>

          <MetLifeButton data-testid="SelectPaymentGateways_next">
            Next
          </MetLifeButton>
        </div>
      </form>
      <UpdateMobileNumberModal
        showModal={showUpdateMobileNumberModal}
        initialValue={policy?.policyOwnerMobile}
        onClose={() => setShowUpdateMobileNumberModal(false)}
        onSubmit={(n: any) => {
          handleChangeMobileNumber(n.updateMobileNumber);
        }}
      />
      <AlertPopup
        alertWrapperCustomClass="cf-custom-modal-wrapper"
        isVisible={showRequestSentAlert}
        onCloseAlert={() => {
          setShowRequestSentAlert(false);
        }}
        onPressPrimary={() => {
          setShowRequestSentAlert(false);
        }}
        primaryBtnText="Confirm"
        heading={REQUEST_SUBMITTED_HEADING}
        description={REQUEST_SUBMITTED_DESC}
        description2=""
      />
    </div>
  );
};
