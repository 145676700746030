import moment from "moment";

export const getCurrentDate = () => {
  return new Date();
};
export const getSubtractedDate = (
  current_date: Date,
  { years = 0, months = 0, days = 0 }
) => {
  if (years) {
    return moment(current_date).subtract(years, "years").toDate();
  }
  return current_date;
};
