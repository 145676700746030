import {
  ConfirmPayment,
  PaymentDone,
  PaymentOverview,
  PaymentStatusCheck,
  PaymentType,
  PolicyDetails,
  VerifyOTP,
} from "@metlife-one-opps/direct-payments";
import { AuthProvider, LoaderProvider } from "@metlife-one-opps/hooks";
import { PageContainer } from "@metlife-one-opps/page-container";
import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const PageNotFound = () => {
  return (
    <PageContainer
      pageTitle="404 - Page Not Found"
      pageSubTitle="This page is not found."
    >
      <div>
        <h1>404 - Page Not Found.</h1>
        <p>This page is broken or not available at this moment.</p>
      </div>
    </PageContainer>
  );
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <PolicyDetails />
      </AuthProvider>
    ),
  },
  {
    path: "/payment-type",
    element: (
      <AuthProvider>
        <PaymentType />
      </AuthProvider>
    ),
  },
  {
    path: "/verify-otp",
    element: (
      //<AuthProvider>
      <VerifyOTP />
      //</AuthProvider>
    ),
  },
  {
    path: "/confirm-payment",
    element: (
      <AuthProvider>
        <ConfirmPayment />
      </AuthProvider>
    ),
  },
  {
    path: "/payment-overview",
    element: (
      <AuthProvider>
        <PaymentOverview />
      </AuthProvider>
    ),
  },
  {
    path: "/payment-status",
    element: (
      <AuthProvider>
        <PaymentStatusCheck />
      </AuthProvider>
    ),
  },
  {
    path: "/payment-done",
    element: <PaymentDone />,
  },
  // Handle 404 - Page Not Found
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

const App: React.FC<any> = () => {
  return (
    <>
      <LoaderProvider>
        <RouterProvider router={router} />
      </LoaderProvider>
    </>
  );
};

export default App;
