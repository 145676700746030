import { formatAmount } from "@metlife-one-opps/utils";
import {
  connectIps,
  eSewaImage,
  fonePayImage,
  imePayImage,
  khaltiImage,
} from "../assets";

export const paymentGatewaysList = [
  {
    id: 1,
    altText: "ESEWA",
    value: "eSewa Pay",
    label: "eSewa",
    url: eSewaImage,
  },
  {
    id: 2,
    value: "fonepay",
    altText: "FONEPAY",
    label: "Fonepay",
    url: fonePayImage,
    isDisabled: false,
  },
  {
    id: 3,
    altText: "CONNECTIPS",
    value: "connect IPS",
    label: "Connect IPS",
    url: connectIps,
    isDisabled: false,
  },
  {
    id: 4,
    value: "Khalti pay",
    altText: "KHALTI",
    label: "Khalti",
    url: khaltiImage,
    isDisabled: false,
  },
  {
    id: 5,
    value: "ime pay",
    altText: "IMEPAY",
    label: "IME Pay",
    url: imePayImage,
    isDisabled: false,
  },
];

export function getOptionList(
  policy: { payableAmount?: any; paymentMode: any },
  paymentTypeSelected: any
) {
  let options: any = [
    {
      id: 1,
      label: "Pay Statement Balance",
      value:
        policy?.payableAmount?.split(" ")[0] +
        " " +
        formatAmount(Number(policy?.payableAmount.split(" ")[1])),
    },
  ];

  if (paymentTypeSelected?.value !== "premium") {
    options.push({ id: 2, label: "Pay Any Amount" });
  }

  return options;
}

export const getPayableAmount = (policy: any, otherAmount: any) => {
  if (otherAmount) return otherAmount;
  return Number(policy?.payableAmount.split(" ")[1]) || 0;
};

export const paymentFailedMessage = {
  line1: "Please try again or contact us at ",
  contactNumber: "01-5970166 ",
  line2: "for support",
};
