import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { Loader } from "@metlife-one-opps/loader";
const contextState = {
  loading: false,
  setLoading: (v) => {},
};

export const LoaderContext = createContext(contextState);

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [loading]);

  const loaderContextValue = useMemo(() => {
    return { loading, setLoading };
  }, [loading, setLoading]);

  return (
    <LoaderContext.Provider value={loaderContextValue}>
      {children}
      {loading && <Loader />}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  return useContext(LoaderContext);
};
