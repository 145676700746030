import React from "react";
import classes from "./MetLifeButton.module.css";

type MetLifeButtonProps = {
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  type?: string | "submit";
  className?: string;
  variant?: string;
  id?: string;
};

export const MetLifeButton: React.FC<MetLifeButtonProps> = ({
  onClick,
  children,
  type = "submit",
  className = "",
  variant = "primary",
  style,
  id = "",
  ...props
}) => {
  const buttonClass =
    variant === "primary"
      ? classes["App__primary-btn"]
      : classes["App__secondary-btn"];
  return (
    <button
      onClick={onClick}
      className={`${buttonClass} ${className}`}
      type={type as "submit" | "reset" | "button"}
      style={style}
      id={id}
      {...props}
    >
      {children}
    </button>
  );
};
